import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom"
import { Button, Container, Table } from 'reactstrap';
import ModalAddVendorEids from "./ModalAddVendorEids"
import { IVendor, TaddResult } from '../interfaces'
import { remote } from '../util/remote';
import { AuthContext } from "../util/authcontext"

const Vendors = () => {
    const [vendors, setVendors] = useState<IVendor[]>([]);
    const [needUpdate, setNeedUpdate] = useState<number>(0)

    const auth = useContext(AuthContext)
    const history = useHistory();

    useEffect(() => {
        const loadData = async () => {
            if (auth.token) {
                const res = await remote.get(`/vendor`)
                setVendors(await res.data);
            }
        };
        loadData();
        return () => { };
    }, [auth, needUpdate]);

    const addEidsCB = (data: TaddResult) => {
        if (data.success > 0) setNeedUpdate(needUpdate + 1)
    }

    let lineNum: number = 1;
    const vendorList = vendors.map((el) => {
        return <tr key={lineNum} >
            <th scope="row">{lineNum++}</th>
            <td>{el.company}</td>
            <td>{el.firstname} {el.lastname}</td>
            <td><span className={el.status ? "Active" : "inActive"}>{el.status ? "Active" : "Disabled"}</span></td>
            <td>
                <NavLink to={`/users/vendor/${el._id}`}>{el.users}</NavLink>&nbsp;
            </td>
            <td>
                <NavLink to={`/eids/vendor/${el._id}`}>{el.cards}</NavLink>&nbsp;/&nbsp;
                <NavLink to={`/eids/vendor/${el._id}/1/onboard`}><span className="vendordisableseid">{el.onboard}</span></NavLink>
            </td>
            <td>
                <div className="d-flex justify-content-between align-items-center width-icons-fix">
                    <Button size="sm" className="btn-clean btn-icon" title="Edit Vendor" onClick={() => history.push(`/vendor/edit/${el._id}`)}><i className="fas fa-user-edit"></i></Button>
                    <ModalAddVendorEids {...el} cb={addEidsCB} />
                </div>
            </td>
        </tr>
    })
    return (
        <Container>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>COMPANY</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>USERS</th>
                        <th>CARDS</th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    {vendorList}
                </tbody>
            </Table>
        </Container>
    );
}

export default Vendors;