
import React, { useState, useEffect, useContext } from 'react';
import { IUsers } from '../interfaces'
import { remote } from '../util/remote';
import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "../util/authcontext"
import {
    Attachment2,
    ChartBar1,
    GroupChat,
    Library,
    MailNotification,
    MailOpened,
    Notification2,
    SelectedFile,
    Write
} from "../img/icons"

type Mprops = {
    //submitFn(url: string): void;
}

const ModalUserProfile = (props: Mprops) => {
    const [iotUser, setUser] = useState<IUsers>();
    const {
        user,
        logout,
    } = useAuth0();

    const auth = useContext(AuthContext)

    useEffect(() => {
        const loadData = async () => {
            const res = await remote.get(`/users`)
            setUser(await res.data[0]);
        };
        if (auth.token)
            loadData();
        return () => { };
    }, [auth]);

    const logoutWithRedirect = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logout({
            returnTo: window.location.origin,
        });
    }

    if (!iotUser) return (<div id="kt_quick_user" className="offcanvas offcanvas-right p-10"></div>)

    return (
        <React.Fragment>
            <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
                {/* <!-- begin::User Panel--> */}
                {/* <!--begin::Header--> */}
                <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                    <h3 className="font-weight-bold m-0">
                        User Profile
                    <small className="text-muted font-size-sm ml-2">12 messages</small>
                    </h3>
                    <a href="/" onClick={() => { }} className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                        <i className="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
                {/* <!--end::Header--> */}

                {/* <!--begin::Content--> */}
                <div className="offcanvas-content pr-5 mr-n5">
                    {/* <!--begin::Header--> */}
                    <div className="d-flex align-items-center mt-5">
                        <div className="symbol symbol-100 mr-5">
                            <div className="symbol-label" style={{ backgroundImage: "url('assets/media/users/300_21.jpg')" }}></div>
                            <i className="symbol-badge bg-success"></i>
                        </div>
                        <div className="d-flex flex-column">
                            <a href="/" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                                {iotUser!.firstname}{iotUser!.lastname}
                            </a>
                            <div className="text-muted mt-1">
                                Application Developer
                        </div>
                            <div className="navi mt-2">
                                <a href="/" className="navi-item">
                                    <span className="navi-link p-0 pb-2">
                                        <span className="navi-icon mr-1">
                                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg--> */}
                                                <MailNotification />
                                                {/* <!--end::Svg Icon--> */}
                                            </span>							</span>
                                        <span className="navi-text text-muted text-hover-primary">{user?.email}</span>
                                    </span>
                                </a>

                                <a href="/" onClick={(e) => logoutWithRedirect(e)} className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--end::Header--> */}

                    {/* <!--begin::Separator--> */}
                    <div className="separator separator-dashed mt-8 mb-5"></div>
                    {/* <!--end::Separator--> */}

                    {/* <!--begin::Nav--> */}
                    <div className="navi navi-spacer-x-0 p-0">
                        {/* <!--begin::Item--> */}
                        <a href="custom/apps/user/profile-1/personal-information.html" className="navi-item">
                            <div className="navi-link">
                                <div className="symbol symbol-40 bg-light mr-3">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-md svg-icon-success">
                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Notification2.svg--> */}
                                            <Notification2 />
                                            {/* <!--end::Svg Icon--> */}
                                        </span>						</div>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold">
                                        My Profile
                                </div>
                                    <div className="text-muted">
                                        Account settings and more
                                    <span className="label label-light-danger label-inline font-weight-bold">update</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        {/* <!--end:Item--> */}

                        {/* <!--begin::Item--> */}
                        <a href="custom/apps/user/profile-3.html" className="navi-item">
                            <div className="navi-link">
                                <div className="symbol symbol-40 bg-light mr-3">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Chart-bar1.svg--> */}
                                            <ChartBar1 />
                                            {/* <!--end::Svg Icon--> */}
                                        </span> 					   </div>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold">
                                        My Messages
                                </div>
                                    <div className="text-muted">
                                        Inbox and tasks
                                </div>
                                </div>
                            </div>
                        </a>
                        {/* <!--end:Item--> */}

                        {/* <!--begin::Item--> */}
                        <a href="custom/apps/user/profile-2.html" className="navi-item">
                            <div className="navi-link">
                                <div className="symbol symbol-40 bg-light mr-3">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            {/* </span><!--begin::Svg Icon | path:assets/media/svg/icons/Files/Selected-file.svg--> */}
                                            <SelectedFile />
                                            {/* <!--end::Svg Icon--> */}
                                        </span>						</div>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold">
                                        My Activities
                                </div>
                                    <div className="text-muted">
                                        Logs and notifications
                                </div>
                                </div>
                            </div>
                        </a>
                        {/* <!--end:Item--> */}

                        {/* <!--begin::Item--> */}
                        <a href="custom/apps/userprofile-1/overview.html" className="navi-item">
                            <div className="navi-link">
                                <div className="symbol symbol-40 bg-light mr-3">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg--> */}
                                            <MailOpened />
                                            {/* </div><!--end::Svg Icon--> */}
                                        </span>						</div>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold">
                                        My Tasks
                                </div>
                                    <div className="text-muted">
                                        latest tasks and projects
                                </div>
                                </div>
                            </div>
                        </a>
                        {/* <!--end:Item--> */}
                    </div>
                    {/* <!--end::Nav--> */}

                    {/* <!--begin::Separator--> */}
                    <div className="separator separator-dashed my-7"></div>
                    {/* <!--end::Separator--> */}

                    {/* <!--begin::Notifications--> */}
                    <div>
                        {/* <!--begin:Heading--> */}
                        <h5 className="mb-5">
                            Recent Notifications
                    </h5>
                        {/* <!--end:Heading--> */}

                        {/* <!--begin::Item--> */}
                        <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                            <span className="svg-icon svg-icon-warning mr-5">
                                <span className="svg-icon svg-icon-lg">
                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg--> */}
                                    <Library />
                                    {/* <!--end::Svg Icon--> */}
                                </span>	            </span>

                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="/" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Another purpose persuade</a>
                                <span className="text-muted font-size-sm">Due in 2 Days</span>
                            </div>

                            <span className="font-weight-bolder text-warning py-1 font-size-lg">+28%</span>
                        </div>
                        {/* <!--end::Item--> */}

                        {/* <!--begin::Item--> */}
                        <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
                            <span className="svg-icon svg-icon-success mr-5">
                                <span className="svg-icon svg-icon-lg">
                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg--> */}
                                    <Write />
                                    {/* <!--end::Svg Icon--> */}
                                </span>	            </span>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="/" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Would be to people</a>
                                <span className="text-muted font-size-sm">Due in 2 Days</span>
                            </div>

                            <span className="font-weight-bolder text-success py-1 font-size-lg">+50%</span>
                        </div>
                        {/* <!--end::Item--> */}

                        {/* <!--begin::Item--> */}
                        <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
                            <span className="svg-icon svg-icon-danger mr-5">
                                <span className="svg-icon svg-icon-lg">
                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg--> */}
                                    <GroupChat />
                                    {/* <!--end::Svg Icon--> */}
                                </span>	            </span>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="/" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">Purpose would be to persuade</a>
                                <span className="text-muted font-size-sm">Due in 2 Days</span>
                            </div>

                            <span className="font-weight-bolder text-danger py-1 font-size-lg">-27%</span>
                        </div>
                        {/* <!--end::Item--> */}

                        {/* <!--begin::Item--> */}
                        <div className="d-flex align-items-center bg-light-info rounded p-5">
                            <span className="svg-icon svg-icon-info mr-5">
                                <span className="svg-icon svg-icon-lg">
                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Attachment2.svg--> */}
                                    <Attachment2 />
                                    {/* <!--end::Svg Icon--> */}
                                </span>	            </span>

                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="/" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">The best product</a>
                                <span className="text-muted font-size-sm">Due in 2 Days</span>
                            </div>

                            <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
                        </div>
                        {/* <!--end::Item--> */}
                    </div>
                    {/* <!--end::Notifications--> */}
                </div>
                {/* <!--end::Content--> */}
                {/* <!-- end::User Panel-->          */}
            </div>
            {/* </Modal> */}
        </React.Fragment>
    )
}

export default ModalUserProfile