import React, { useEffect, useState } from 'react';
import Select, { ValueType, OptionsType } from "react-select";
import { Button, Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TvendorOption } from '../../interfaces'

type Mprops = {
    vlist: OptionsType<TvendorOption>;
    u: any[];
    submitFn(v: TvendorOption, eidIds: string[]): void;
}

const noVendor: ValueType<TvendorOption, false> = { value: "", label: "Select vendor" }

export const SelectVendor = (props: Mprops) => {

    const [eidIds, setEidIds] = useState<string[]>([])

    useEffect(() => {
        if (props.u && props.u.length !== 0) {
            const ids = props.u
            setEidIds([...ids])
        }
    }, [props.u])

    const [modal, setModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState<ValueType<TvendorOption, false> | null>(noVendor);

    const submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        if (selectedOption) {
            props.submitFn(selectedOption, eidIds);
        }
        toggle()
    };
    const toggle = () => setModal(!modal);


    return (
        <div>
            <Button color="light selectv" onClick={toggle}>Set vendor</Button>{' '}
            <Modal isOpen={modal} toggle={toggle} className="">
                <ModalHeader toggle={toggle}>Select Vendor:</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <Label for="vendorlist">Vendors:</Label>{' '}
                            <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={props.vlist}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="primary" onClick={submit}>Set</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SelectVendor;