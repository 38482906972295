export interface IState {
    cards: any[] | null;
    profiles: IICCProfile[];
}

export interface ICard {
    EID: string
}

export interface IICCProfile {
    ICCID: string,
    source: string,
    isdpAid: string,
    profileState: string,
    serviceProviderName: string,
    profileName: string,
    profileClass: string,
    dtCreate: Date,
    eid: string
}

export enum ICType {
    EnableProfile,
    DisableProfile,
    DeleteProfile
}

export interface ICommands {
    type: ICType,
    ICCID: string,
    EID: string,
    dtCreate: Date
}

export interface IICCxProfile extends IICCProfile {
    hidden: boolean;
    activateCmd?: ICommands[];
    waiting4Command(cmd: ICType): boolean;
}

const backendHost: string = process.env.REACT_APP_BACKEND || 'https://mautic-testic.ml';
export const backend: string = `${backendHost}/client/v1`;

export interface IVendor {
    _id: string,
    firstname: string,
    lastname: string,
    company: string,
    phone: string,
    email: string,
    users: number,
    cards: number,
    onboard: number,
    profiles: number,
    status: boolean,
    dtCreate: Date
}

export type TaddResult = {
    success: number;
    fail: number;
}

export interface IUsers {
    _id: string,
    firstname: string;
    lastname: string;
    login: string;
    phone: string;
    email: string;
    email2: string;
    password: string;
    password2: string;
    status: boolean;
    vendor: string,
    company: string;
    cards: number,
    profiles: number,
    dtCreate: Date
}

export type TvendorOption = {
    value: string;
    label: string;
}

export type TListPaginationProps = {
    mainHref: string;
    total: number;
}

export type TListPageInfoProps = {
    total: number;
}

export type TactionType = { type: 'set', size: string }
