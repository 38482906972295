import React, { useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type Mprops = {
  buttonLabel: string;
  className: string;
  submitFn(url: string): void;
}


const ModalSendProfile = (props: Mprops) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [smdpurl, setSmdpurl] = useState("smdp.ruimtools.com:8083");
  const [matchId, setMatchId] = useState("NUAN-D///-1234-XXX3");
  const [codeCheck, setCodeCheck] = useState(false);
  const [accesCheck, setAccesCheck] = useState("");

  const submit = () => { props.submitFn(`1$${smdpurl}$${matchId}$${accesCheck}`); setModal(!modal) };
  const toggle = () => setModal(!modal);
  const changeSmdpurl = (e: React.ChangeEvent<HTMLInputElement>) => setSmdpurl(e.target.value)
  const changeMatchId = (e: React.ChangeEvent<HTMLInputElement>) => setMatchId(e.target.value)
  const changeCodeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) setAccesCheck("1")
    else setAccesCheck("")
    setCodeCheck(e.currentTarget.checked)
  }

  return (
    <div>
      <Button className="btn-sm btn-clean btn-icon no-bg" title="Download profile via QR" onClick={toggle}><i className="fas fa-qrcode" /></Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Download profile QR generation:</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <Label for="smdpurl">SMDP+ URL:</Label>{' '}
              <Input type="url" name="smdpurl" id="smdpurl" value={smdpurl} onChange={changeSmdpurl}>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="matching-id">Matching ID:</Label>{' '}
              <Input type="text" name="matching-id" id="matching-id" value={matchId} onChange={changeMatchId}>
              </Input>
            </FormGroup>
            <FormGroup >
			<div className="checkbox-list">
			<label className="checkbox checkbox-single"><input type="checkbox" name="accesscode" checked={codeCheck} onChange={changeCodeCheck} /><span></span>Access is code required</label>
			</div>
            </FormGroup>
            <FormGroup>
              <Label for="viewurl">View URL:</Label>{' '}
              <Input type="text" disabled name="viewurl" id="viewurl" value={`1$${smdpurl}$${matchId}$${accesCheck}`}>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
		  <Button color="secondary" onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={submit}>{buttonLabel}</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalSendProfile;

// eslint-disable-next-line
const original: string = `
<div className="modal fade" id="exampleModalCenter" data-backdrop="static" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Download profile QR generation:</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" className="ki ki-close"></i>
                                </button>
                            </div>
                            <div className="modal-body">

								<div className="form-group">
                                    <label>SMDP+ URL:</label>
                                    <input type="url" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Matching ID:</label>
                                    <input type="matching-id" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <div className="checkbox-list">
                                        <label className="checkbox checkbox-outline">
                                            <input type="checkbox" name="Checkboxes1" />
                                            <span></span>
                                 Access is code required
										</label>
                                    </div>
                                </div>
								<div className="form-group">
                                    <label>View URL:</label>
                                    <input type="email" className="form-control" disabled={true} placeholder="1$smdp.ruimtools.com:8083$NUAN-D///-1234-XXX3$1" />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary font-weight-bold">Send</button>
                            </div>
                        </div>
                    </div>
                </div>`;