import React from "react"
import { TactionType } from '../interfaces'
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!

export const PageSizeContext = React.createContext({
    pageSize: "10",
    pSizeDispatch: (value: TactionType): void => { }
});

export const pageSizeReducer = (size: string, action: TactionType) => {
    switch (action.type) {
        default:
            return action.size;
    }
}
