import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { backend } from '../interfaces'

export interface Iremote {
    set_conf(conf: AxiosRequestConfig):void;
    del(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>>;
    post(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>>;
    patch(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>>;
    get(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>>;
}

class Remote {
    private config: AxiosRequestConfig = {}
    set_conf(conf: AxiosRequestConfig):void {
        this.config = conf;
    }
    del(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> {
        return axios.delete(`${backend}${url}`, config||this.config);
    }

    post(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> {
        return axios.post(`${backend}${url}`, data, config||this.config);
    }

    patch(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> {
        return axios.patch(`${backend}${url}`, data, config||this.config);
    }

    get(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> {
        return axios.get(`${backend}${url}`, config||this.config);
    }
}
export const remote: Iremote = new Remote();
