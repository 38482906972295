import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, CardBody, CardFooter, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { useHistory, useParams } from "react-router-dom"
import { remote } from '../../util/remote'
import { AuthContext } from "../../util/authcontext"
import { IUsers } from '../../interfaces'

type TCreateUser = Omit<IUsers,
    "_id" | "vendor" | "cards" | "profiles" | "password2" | "email2" | "company">

export const EditUser = () => {
    const [user, setUser] = useState<TCreateUser>({
        firstname: "",
        lastname: "",
        login: "",
        phone: "",
        email: "",
        password: "",
        status: true,
        dtCreate: new Date()
    })

    const auth = useContext(AuthContext)
    const history = useHistory();
    const { uid } = useParams<{ uid: string }>()
    const [info, setInfo] = useState("");

    useEffect(() => {
        const loadData = async () => {
            if (auth.token) {
                const res = await remote.get(`/users/${uid}`)
                setUser(await res.data[0]);
            }
        };
        loadData();
        return () => { };
    }, [auth, uid]);

    const handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
        if (info.length > 0) setInfo("")
        e.preventDefault();
        setUser({
            ...user,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const processFormSubmission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        remote.patch(`/users/${uid}`, user).then((result) => {
            setTimeout(() => {
                history.goBack()
            }, 2500)
        },
            (err) => {
                if (err.response) {
                    //console.log(err.response.data)
                    setInfo(err.response.data.message)
                }
                else {
                    setInfo(err.message)
                }
            }
        )
    }
    const deleteUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        remote.del(`/users/${uid}`).then((result) => {
            setTimeout(() => {
                history.goBack()
            }, 2500)
        },
            (err) => {
                console.log(err)
            }
        )
    }
    return (
        <Container fluid={true}>
            <Row>
                edit user {uid} created {new Date(user.dtCreate).toLocaleString('de-DE')}
            </Row>
            <Row hidden={!info}>
                <Alert color="info">
                    {info}
                </Alert>
            </Row>
            <Form className="form" onSubmit={(e) => e.preventDefault()}>
                <CardBody>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="firstname" className="v-label">First name:</Label>
                            <Input
                                type="text"
                                name="firstname"
                                value={user.firstname}
                                onChange={handleInputChanges}
                                placeholder="Enter first name" />
                            <FormText>Please enter your first name</FormText>
                        </Col>
                        <Col lg={6}>
                            <Label for="lastname">Last name:</Label>
                            <Input
                                type="text"
                                name="lastname"
                                value={user.lastname}
                                onChange={handleInputChanges}
                                placeholder="Enter last name" />
                            <FormText>Please enter your first name</FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="username">Username:</Label>
                            <Input
                                type="text"
                                name="username"
                                value={user.email}
                                disabled={true}
                                placeholder="Enter Username" />
                            <FormText>Please enter your Username</FormText>
                        </Col>
                        <Col lg={6}>
                            <Label for="phone">Phone number:</Label>
                            <Input
                                type="tel"
                                name="phone"
                                value={user.phone||""}
                                onChange={handleInputChanges}
                                placeholder="Enter Phone number" />
                            <FormText>Please enter your Phone number</FormText>
                        </Col>
                    </FormGroup>
                    <div className="separator separator-dashed my-10"></div>

                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="email">E-mail:</Label>
                            <Input
                                type="email"
                                name="email"
                                value={user.email}
                                onChange={handleInputChanges}
                                placeholder="Enter E-mail" />
                            <FormText>Please enter new E-mail</FormText>
                        </Col>
                        <Col lg={6}>
                            <Label for="password">Password:</Label>
                            <Input
                                type="password"
                                name="password"
                                value={user.password}
                                onChange={handleInputChanges}
                                placeholder="Enter Password" />
                            <FormText>Please enter new password</FormText>
                        </Col>
                    </FormGroup>

                    <div className="separator separator-dashed my-10"></div>
                    <FormGroup row>
                        <label className="col-1 col-form-label">Status</label>
                        <div className="col-1">
                            <span className="switch switch-sm switch-icon top-m-fix-switch">
                                <label>
                                    <Input
                                        type="checkbox"
                                        name="select"
                                        checked={user.status}
                                        onChange={() => setUser({ ...user, status: !user.status })}
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </FormGroup>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col lg={6}>
                            <Button type="reset" color="primary" onClick={processFormSubmission}>Save</Button>
                            <Button type="reset" color="secondary" onClick={() => history.goBack()}>Cancel</Button>
                        </Col>
                        <Col lg={6} className="text-right">
                            <Button type="reset" color="danger" onClick={deleteUser}>Delete</Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Form>
        </Container>
    )
}

export default EditUser;