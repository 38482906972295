import React, { useState, useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import { Button, CardBody, CardFooter, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { remote } from '../../util/remote';
import { AuthContext } from "../../util/authcontext"

const EditVendor = (props: RouteComponentProps<any>) => {
    //console.log(props.match.params.vid)
    const auth = useContext(AuthContext)
    const [vendor, setVendor] = useState<{
        firstname: string;
        lastname: string;
        phone: string;
        email: string;
        email2: string;
        company: string;
        status: boolean;
    }>({
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        email2: "",
        company: "",
        status: true
    })

    const vid = props.match.params.vid;
    useEffect(() => {
        const loadData = async () => {
            if (auth.token) {
                const res = await remote.get(`/vendor/${vid}`)
                setVendor(await res.data[0]);
            }
        };
        loadData();
        return () => { };
    }, [auth, vid]);

    const processFormSubmission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        remote.patch(`/vendor/${vid}`, vendor).then((result) => {
            setTimeout(() => {
                props.history.push('/vendor/list')
            }, 2500)
        },
            (err) => {
                console.log(err)
            }
        )
    }

    const handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setVendor({
            ...vendor,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const deleteVendor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();
        remote.del(`/vendor/${vid}`).then((result) => {
            setTimeout(() => {
                props.history.push('/vendor/list')
            }, 2500)
        })
    }
    return (
        <Container>
            <Form className="form" onSubmit={(e) => e.preventDefault()}>
                <CardBody>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="firstname" className="v-label">First name:</Label>
                            <Input
                                type="text"
                                name="firstname"
                                value={vendor.firstname}
                                onChange={handleInputChanges}
                                placeholder="Enter first name" />
                            <FormText>Please enter your first name</FormText>
                        </Col>
                        <Col lg={6}>
                            <Label for="lastname">Last name:</Label>
                            <Input
                                type="text"
                                name="lastname"
                                value={vendor.lastname}
                                onChange={handleInputChanges}
                                placeholder="Enter last name" />
                            <FormText>Please enter your first name</FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="company">Company:</Label>
                            <Input
                                type="text"
                                name="company"
                                value={vendor.company}
                                onChange={handleInputChanges}
                                placeholder="Company name" />
                            <FormText>Please enter company name</FormText>
                        </Col>
                        <Col lg={6}>
                            <Label for="phone">Phone number:</Label>
                            <Input
                                type="tel"
                                name="phone"
                                value={vendor.phone}
                                onChange={handleInputChanges}
                                placeholder="Enter Phone number" />
                            <FormText>Please enter your Phone number</FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label for="email">E-mail:</Label>
                            <Input
                                type="email"
                                name="email"
                                value={vendor.email}
                                onChange={handleInputChanges}
                                placeholder="Enter E-mail" />
                            <FormText>Please enter your E-mail</FormText>
                        </Col>
                    </FormGroup>

                    <div className="separator separator-dashed my-10"></div>

                    <FormGroup row>
                        <label className="col-1 col-form-label">Status</label>
                        <div className="col-1">
                            <span className="switch switch-sm switch-icon top-m-fix-switch">
                                <label>
                                    <Input
                                        type="checkbox"
                                        checked={vendor.status}
                                        onChange={() => setVendor({ ...vendor, status: !vendor.status })}
                                        name="status"
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <Row>
                        <Col lg={6}>
                            <Button type="reset" color="primary" onClick={processFormSubmission}>Save</Button>
                            <Button type="reset" color="secondary" onClick={() => props.history.push("/vendor/list")}>Cancel</Button>
                        </Col>
                        <Col lg={6} className="text-right">
                            <Button type="reset" color="danger" onClick={deleteVendor}>Delete</Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Form>
        </Container>
    )
}

export default EditVendor;