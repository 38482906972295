import React, { useState, useRef } from 'react';
import { RouteComponentProps } from "react-router"
import { Button, CardBody, CardFooter, Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { remote } from '../../util/remote'

export interface IValues {
	EID: string,
	IMEI: string,
	PLMN: string,
	AN: string,
}
export interface IFormState {
	[key: string]: any;
	values: IValues[];
	submitSuccess: boolean;
	loading: boolean;
}

export const CreateVendor = (props: RouteComponentProps) => {

	const [vendor, setVendor] = useState<{
		firstname: string;
		lastname: string;
		phone: string;
		email: string;
		email2: string;
		company: string;
		status: boolean;
	}>({
		firstname: "",
		lastname: "",
		phone: "",
		email: "",
		email2: "",
		company: "",
		status: true
	})
	const [submitSuccess, setSubmitSuccess] = useState(false)

	const executeScroll = () => infoRef.current?.scrollIntoView(false)
	const processFormSubmission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		remote.post(`/vendor/create`, vendor).then((result) => {
			setSubmitSuccess(true)
			setTimeout(() => {
				props.history.push('/vendor/list')
			}, 2500)
		},
		(err) => {
				executeScroll();
				console.log(err)
			}
		)
		//this.setState({ submitSuccess: true, values: [...this.state.values, formData], loading: false });
	}

	const handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		setVendor({
			...vendor,
			[e.currentTarget.name]: e.currentTarget.value
		})
	}

	const infoRef = useRef<HTMLDivElement>(null)
	return (
		<div>
			<div ref={infoRef} className={"col-md-12 form-wrapper"}>
				{!submitSuccess && (
					<div className="alert alert-info" role="alert">
						Fill the form below to create a new vendor
					</div>
				)}
				{submitSuccess && (
					<div className="alert alert-info" role="alert">
						The form was successfully submitted!
					</div>
				)}
				<Form className="form" onSubmit={(e) => e.preventDefault()}>
					<CardBody>
						<FormGroup row>
							<Col lg={6}>
								<Label for="firstname" className="v-label">First name:</Label>
								<Input
									type="text"
									name="firstname"
									value={vendor.firstname}
									onChange={handleInputChanges}
									placeholder="Enter first name" />
								<FormText>Please enter your first name</FormText>
							</Col>
							<Col lg={6}>
								<Label for="lastname">Last name:</Label>
								<Input
									type="text"
									name="lastname"
									value={vendor.lastname}
									onChange={handleInputChanges}
									placeholder="Enter last name" />
								<FormText>Please enter your first name</FormText>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col lg={6}>
								<Label for="company">Company:</Label>
								<Input
									type="text"
									name="company"
									value={vendor.company}
									onChange={handleInputChanges}
									placeholder="Company name" />
								<FormText>Please enter company name</FormText>
							</Col>
							<Col lg={6}>
								<Label for="phone">Phone number:</Label>
								<Input
									type="tel"
									name="phone"
									value={vendor.phone}
									onChange={handleInputChanges}
									placeholder="Enter Phone number" />
								<FormText>Please enter your Phone number</FormText>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col lg={6}>
								<Label for="email">E-mail:</Label>
								<Input
									type="email"
									name="email"
									value={vendor.email}
									onChange={handleInputChanges}
									placeholder="Enter E-mail" />
								<FormText>Please enter your E-mail</FormText>
							</Col>
							<Col lg={6}>
								<Label for="email">Repeat E-mail:</Label>
								<Input
									type="email"
									name="email2"
									value={vendor.email2}
									onChange={handleInputChanges}
									placeholder="Repeat E-mail" />
								<FormText>Please repeat your E-mail</FormText>
							</Col>
						</FormGroup>

						<div className="separator separator-dashed my-10"></div>

						<FormGroup row>
							<label className="col-1 col-form-label">Status</label>
							<div className="col-1">
								<span className="switch switch-sm switch-icon top-m-fix-switch">
									<label>
										<Input
											type="checkbox"
											checked={vendor.status}
											onChange={() => setVendor({ ...vendor, status: !vendor.status })}
											name="status"
										/>
										<span></span>
									</label>
								</span>
							</div>
						</FormGroup>
					</CardBody>

					<CardFooter>
						<Row>
							<Col lg={6}>
								<Button type="reset" color="primary" onClick={processFormSubmission}>Save</Button>
								<Button type="reset" color="secondary" onClick={() => props.history.push("/vendor/list")}>Cancel</Button>
							</Col>
							<Col lg={6} className="text-right">
								{/* <Button type="reset" color="danger">Delete</Button> */}
							</Col>
						</Row>
					</CardFooter>
				</Form>
			</div>
		</div>
	)
}

export default CreateVendor