import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from "../../util/authcontext"
import { PageSizeContext } from "../../util/pagesizecontext"
import { NewList } from "./List"
import { makeApiListRoute } from "../../util/util"

const VendorListEids = () => {
    const auth = useContext(AuthContext)
    const { pageSize } = useContext(PageSizeContext)
    const { vid, page, onboard } = useParams<{ vid: string, page: string, onboard: string }>()
    console.log(vid, page, onboard)
    const listRoute = makeApiListRoute(`/list/vendor/${vid}`, page, onboard, pageSize)

    /*     const addSearch = (filter: string) => {
            history.push(`/list/filter/${filter}`);
        } */
    return (
        <NewList
            key={auth.user}
            token={auth.token}
            mainHref={`/eids/vendor/${vid}`}
            listRoute={listRoute}
        ></NewList>
    )
}

export default VendorListEids