import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { remote } from '../../util/remote';

export interface IValues {
    EID: string,
    IMEI: string,
    PLMN: string,
    AN: string,
}
export interface IFormState {
    [key: string]: any;
    values: IValues[];
    submitSuccess: boolean;
    loading: boolean;
}

interface createProps extends WithAuth0Props, RouteComponentProps {}

class Create extends React.Component<createProps, IFormState> {
    constructor(props: createProps) {
        super(props);
        this.state = {
            EID: '',
            IMEI: '',
            PLMN: '',
            AN: '',
            values: [],
            loading: false,
            submitSuccess: false,
        }
    }

    private async getAuthHeader(): Promise<boolean> {
        return this.props.auth0.getAccessTokenSilently().then((token) => {
            remote.set_conf({
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return true;
        }, (err) => {
            console.log(err.message);
            //this.props.redirectHome();
            return false;
        })
    }
    public async componentDidMount(): Promise<void> {
        await this.getAuthHeader();
    }

    private processFormSubmission = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.setState({ loading: true });
        const formData = {
            EID: this.state.EID,
            IMEI: this.state.IMEI,
            PLMN: this.state.PLMN,
            AN: this.state.AN,
        }
        this.setState({ submitSuccess: true, values: [...this.state.values, formData], loading: false });
        remote.post(`/`, formData).then(data => [
            setTimeout(() => {
                this.props.history.push('/');
            }, 15 * 1000)
        ]);
    }

    private handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        this.setState({
            [e.currentTarget.name]: e.currentTarget.value,
        })
    }

    public render() {
        const { submitSuccess, loading } = this.state;
        return (
            <div>
                <div className={"col-md-12 form-wrapper"}>
                    <h2> Create Post </h2>
                    {!submitSuccess && (
                        <div className="alert alert-info" role="alert">
                            Fill the form below to create a new post
                    </div>
                    )}
                    {submitSuccess && (
                        <div className="alert alert-info" role="alert">
                            The form was successfully submitted!
                            </div>
                    )}
                    <form id={"create-post-form"} onSubmit={this.processFormSubmission} noValidate={true}>
                        <div className="form-group col-md-12">
                            <label htmlFor="first_name"> EID </label>
                            <input type="text" id="EID" onChange={(e) => this.handleInputChanges(e)} name="EID" className="form-control" placeholder="EID" />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="IMEI"> IMEI </label>
                            <input type="text" id="IMEI" onChange={(e) => this.handleInputChanges(e)} name="IMEI" className="form-control" placeholder="IMEI" />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="PLMN"> PLMN </label>
                            <input type="PLMN" id="PLMN" onChange={(e) => this.handleInputChanges(e)} name="PLMN" className="form-control" placeholder="" />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="sasAcreditationNumber"> sasAcreditationNumber </label>
                            <input type="AN" id="AN" onChange={(e) => this.handleInputChanges(e)} name="AN" className="form-control" placeholder="sasAcreditationNumber" />
                        </div>
                        <div className="form-group col-md-4 pull-right">
                            <button className="btn btn-success" type="submit">
                                Create eSim
                            </button>
                            {loading &&
                                <span className="fa fa-circle-o-notch fa-spin" />
                            }
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withAuth0(withRouter(Create))