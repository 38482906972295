import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Alert, Button, Container, Row, Table } from 'reactstrap';
import { OptionsType } from "react-select";
import { IUsers, TvendorOption } from '../interfaces'
import { remote } from '../util/remote';
import { AuthContext } from "../util/authcontext"

const Users = () => {
    const [users, setUsers] = useState<IUsers[]>([]);
    const [syncStatus, setSyncStatus] = useState<string>("none");
    const [, setAllVendors] = useState<OptionsType<TvendorOption>>([])
    const { vid } = useParams<{ vid: string }>()

    const auth = useContext(AuthContext)

    useEffect(() => {
        const loadData = async () => {
            if (auth.token) {
                const usersRoute = vid ? `/users/vendor/${vid}` : `/users`
                const res = await remote.get(usersRoute)
                setUsers(await res.data);
                const vlist = await remote.get(`/vendor/list`)
                setAllVendors(vlist.data.map((el: { _id: string, company: string }) => {
                    return {
                        value: el._id,
                        label: el.company
                    }
                }))
            }
        };
        loadData();
        return () => { };
    }, [auth, vid]);

    const callSync = async () => {
        const res = await remote.get(`/users/sync`)
        setSyncStatus(JSON.stringify(res.data));
    }


    let lineNum: number = 1;
    const userList = users.map((el) => {
        return <tr key={lineNum} >
            <th scope="row">{lineNum++}</th>
            <td>{el.firstname} {el.lastname}</td>
            <td><span className={el.status ? "Active" : "inActive"}>{el.status ? "Active" : "Disabled"}</span></td>
            <td>{el.company || "none"}</td>
            <td><div className="d-flex justify-content-between align-items-center width-icons-fix">
            </div>
            </td>
        </tr>
    })
    return (
        <Container>
            <Row hidden={true}>
                <Button outline color="warning" onClick={callSync}>sync</Button>{' '}
                <Alert color="light">{syncStatus}</Alert>
            </Row>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Vendor</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {userList}
                </tbody>
            </Table>
        </Container>
    );
}

export default Users;