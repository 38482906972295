import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class Test extends React.Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        //const { submitSuccess, loading } = this.state;
        return (
            <div>
                <div className={"col-md-12 form-wrapper"}>
                    <h2> PAGE 1 </h2>

                </div>
            </div>
        )
    }
}

export default withRouter(Test)