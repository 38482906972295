import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class Home extends React.Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        //const { submitSuccess, loading } = this.state;
        return (
            <div>
                <div className={"col-md-12 form-wrapper"}>
                    <h2> IG provides everything you need to secure your IoT</h2>
					<br />
					<div className={"separator separator-dashed"}></div>
					<br />
<ul className={"list-unstyled font-size-h4"}>
    <li>A full range of solutions and services for
        <ul>
            <li>IoT connectivity</li>
            <li>Security</li>
			<li>Device management</li>
        </ul>
    </li>
</ul>

                </div>
				
<br />
<div className={"separator separator-dashed"}></div>
<br />	


<div className={"h2"}>Connectivity</div>
<br />

<div className={"row"}>	

<div className={"col-lg-6 col-xl-8 mb-5 mx-auto"}>	
<div className={"card card-custom mb-4 mb-lg-0"}>
 <div className={"card-body"}>
  <div className={"d-flex align-items-center p-5"}>
   <div className={"mr-6"}>
     <img src="/assets/media/iot_1.png" alt="IoT connectivity" />
   </div>
   <div className={"d-flex flex-column"}>
    <a href="/" className={"text-dark text-hover-primary font-weight-bold font-size-h2 mb-3"}>
        IoT connectivity
    </a>
    <div className={"text-dark-75 font-size-h5"}>
    Simple, cost effective cellular connectivity for any device, anywhere in the world.
    </div>
   </div>
  </div>
 </div>
</div>			
</div>				
</div>
<br /><br />

<div className={"row"}>	
<div className={"col-lg-6 col-xl-8 mb-5 mx-auto"}>	
<div className={"card card-custom mb-4 mb-lg-0"}>
 <div className={"card-body"}>
  <div className={"d-flex align-items-center p-5"}>
   <div className={"mr-6"}>
     <img src={"/assets/media/iot_2.png"} alt="IoT connectivity" />
   </div>
   <div className={"d-flex flex-column"}>
    <a href="/" className={"text-dark text-hover-primary font-weight-bold font-size-h2 mb-3"}>
        Connected device platform
    </a>
    <div className={"text-dark-75 font-size-h5"}>
    Take total control of your cellularly connected devices with the Pelion platform. Manage your connectivity cost, monitor your network performance, provision and update devices securely over-the-air.
    </div>
   </div>
  </div>
 </div>
</div>			
</div>								
</div>				


<br />
<div className={"separator separator-dashed"}></div>
<br />	


<div className={"h2"}>Management</div>
<br />

<div className={"row"}>	

<div className={"col-lg-6 col-xl-8 mb-5 mx-auto"}>	
<div className={"card card-custom mb-4 mb-lg-0"}>
 <div className={"card-body"}>
  <div className={"d-flex align-items-center p-5"}>
   <div className={"mr-6"}>
     <img src="/assets/media/iot_3.png" alt="IoT Device Management" />
   </div>
   <div className={"d-flex flex-column"}>
    <a href="/" className={"text-dark text-hover-primary font-weight-bold font-size-h2 mb-3"}>
        IoT Device Management
    </a>
    <div className={"text-dark-75 font-size-h5"}>
    Lifecycle management for any IoT device, over any network available on the cloud or on-premise. Ensure your devices are always up-to-date, secure and delivering peak performance.
    </div>
   </div>
  </div>
 </div>
</div>			
</div>				
</div>
<br /><br />

<div className={"row"}>	
<div className={"col-lg-6 col-xl-8 mb-5 mx-auto"}>	
<div className={"card card-custom mb-4 mb-lg-0"}>
 <div className={"card-body"}>
  <div className={"d-flex align-items-center p-5"}>
   <div className={"mr-6"}>
     <img src={"/assets/media/iot_4.png"} alt="Edge Applications Management" />
   </div>
   <div className={"d-flex flex-column"}>
    <a href="/" className={"text-dark text-hover-primary font-weight-bold font-size-h2 mb-3"}>
        Edge Applications Management
    </a>
    <div className={"text-dark-75 font-size-h5"}>
    Flexible and unified management for devices, gateway systems and applications at the edge. Keep your deployments secure, improve reliability and make sure you are collecting data you can trust.
    </div>
   </div>
  </div>
 </div>
</div>			
</div>							
</div>	


            </div>
        )
    }
}

export default withRouter(Home)