import React, { useReducer } from 'react';
import './App.css';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Create from './components/cards/Create';
import EditCard from './components/cards/Edit';
import Test from './components/cards/Test';
import EidsList from './components/eids/List';
import VendorListEids from './components/eids/VendorListEids';
import VendorList from './components/Vendors';
import CreateVendor from './components/vendor/CreateVendor';
import EditVendor from './components/vendor/EditVendor';
import LogsList from './components/logs/List'
//import ListScripts from './components/scripts/List';
//import EditScript from './components/scripts/Edit';
import UsersList from './components/Users';
import CreateUser from './components/users/CreateUser';
import EditUser from './components/users/EditUser';
import ModalUserProfile from "./components/ModalUserProfile"
import { AuthContext } from './util/authcontext'
import { remote } from './util/remote';
import { PageSizeContext, pageSizeReducer } from "./util/pagesizecontext"

import {
    Library,
    Compiling,
    Search,
    Write,
    GroupChat,
    Attachment2,
    ShieldUser,
    MailNotification,
    Bucket,
    Layout4Blocks,
    FilePlus
} from './img/icons/';

import { LoginFunc } from './components/LoginFunc';
import { SideBar } from './components/SideBar';

const InternalTab = () => {

    const [pageSize, pSizeDispatch] = useReducer(pageSizeReducer, "10");
    return (
        <PageSizeContext.Provider value={{ pageSize, pSizeDispatch }}>
            <Switch>
                <Route path={'/'} exact component={Home} />
                <Route path={'/Dashboard'} exact component={Dashboard} />
                <Route path={['/eids/list/:page?', '/eids/list/:page?/:onboard?']} exact component={EidsList} />
                <Route path={['/eids/vendor/:vid/:page?', '/eids/vendor/:vid/:page?/:onboard?']} exact component={VendorListEids} />
                <Route path={'/vendor/list'} exact component={VendorList} />
                <Route path={'/vendor/create'} exact component={CreateVendor} />
                <Route path={'/vendor/edit/:vid'} exact component={EditVendor} />
                <Route path={'/create'} exact component={Create} />
                <Route path={'/edit/:eid'} exact component={EditCard} />
                <Route path={'/test'} exact component={Test} />
                <Route path={'/scripts/'} exact component={EidsList} />
                <Route path={'/scripts/edit/:eid'} exact component={EditCard} />
                <Route path={'/scripts/new/:eid'} exact component={Create} />
                <Route path={'/users/list'} exact component={UsersList} />
                <Route path={'/users/vendor/:vid'} exact component={UsersList} />
                <Route path={'/users/create'} exact component={CreateUser} />
                <Route path={'/users/edit/:uid'} exact component={EditUser} />
                <Route path={'/logs/list'} exact component={LogsList}/>
            </Switch>
        </PageSizeContext.Provider>
    );
}

interface authcontextState {
    user: any;
    token: boolean;
}

class App extends React.Component<RouteComponentProps<any> & WithAuth0Props, authcontextState> {
    constructor(props: RouteComponentProps & WithAuth0Props) {
        super(props);
        this.state = {
            user: undefined,
            token: false
        }
    }

    private async getAuthHeader(): Promise<boolean> {
        return this.props.auth0.getAccessTokenSilently().then((token) => {
            remote.set_conf({
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            this.setState({ token: true, user: this.props.auth0.user })
            return true;
        }, (err) => {
            console.log(err.message);
            this.props.history.push('/');
            return false;
        })
    }

    public async componentDidMount(): Promise<void> {
        await this.getAuthHeader();
    }

    render() {

        return (
            <AuthContext.Provider value={this.state}>
                <div className="d-flex flex-row flex-column-fluid page">

                    <SideBar />

                    {/* <!--begin::Wrapper--> */}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        {/* <!--begin::Header--> */}
                        <div id="kt_header" className="header  header-fixed " >
                            {/* <!--begin::Container--> */}
                            <div className=" container-fluid  d-flex align-items-stretch justify-content-between">
                                {/* <!--begin::Header Menu Wrapper--> */}
                                <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                                    {/* <!--begin::Header Menu--> */}
                                    <div id="kt_header_menu" className="header-menu header-menu-mobile  header-menu-layout-default " >
                                        {/* <!--begin::Header Nav--> */}

                                        {/* <!--end::Header Nav--> */}
                                    </div>
                                    {/* <!--end::Header Menu--> */}
                                </div>
                                {/* <!--end::Header Menu Wrapper--> */}

                                {/* <!--begin::Topbar--> */}
                                <div className="topbar">
                                    {/* <!--begin::Search--> */}
                                    <div className="dropdown" id="kt_quick_search_toggle">
                                        {/* <!--begin::Toggle--> */}
                                        <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
                                            <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg--> */}
                                                    <Search />
                                                    {/* <!--end::Svg Icon--> */}
                                                </span>		        			</div>
                                        </div>
                                        {/* <!--end::Toggle--> */}

                                        {/* <!--begin::Dropdown--> */}
                                        <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                                            <div className="quick-search quick-search-dropdown" id="kt_quick_search_dropdown">
                                                {/* <!--begin:Form--> */}
                                                <form method="get" className="quick-search-form">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <span className="svg-icon svg-icon-lg">
                                                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg--> */}
                                                                    <Search />
                                                                    {/* <!--end::Svg Icon--> */}
                                                                </span>                </span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Search..." />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="quick-search-close ki ki-close icon-sm text-muted"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* <!--end::Form--> */}

                                                {/* <!--begin::Scroll--> */}
                                                <div className="quick-search-wrapper scroll" data-scroll="true" data-height="325" data-mobile-height="200">
                                                </div>
                                                {/* <!--end::Scroll--> */}
                                            </div>
                                        </div>
                                        {/* <!--end::Dropdown--> */}
                                    </div>
                                    {/* <!--end::Search--> */}

                                    {/* <!--begin::Notifications--> */}
                                    <div className="dropdown">
                                        {/* <!--begin::Toggle--> */}
                                        <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
                                            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
                                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg--> */}
                                                    <Compiling />
                                                    {/* <!--end::Svg Icon--> */}
                                                </span>		                        <span className="pulse-ring"></span>
                                            </div>
                                        </div>
                                        {/* <!--end::Toggle--> */}

                                        {/* <!--begin::Dropdown--> */}
                                        <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                                            <form>
                                                {/* <!--begin::Header--> */}
                                                <div className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top" style={{ backgroundImage: 'url(assets/media/misc/bg-1.jpg)' }}>
                                                    {/* <!--begin::Title--> */}
                                                    <h4 className="d-flex flex-center rounded-top">
                                                        <span className="text-white">User Notifications</span>
                                                        <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">23 new</span>
                                                    </h4>
                                                    {/* <!--end::Title--> */}

                                                    {/* <!--begin::Tabs--> */}
                                                    <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#topbar_notifications_notifications"  >Alerts</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#topbar_notifications_events"  >Events</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#topbar_notifications_logs"  >Logs</a>
                                                        </li>
                                                    </ul>
                                                    {/* <!--end::Tabs--> */}
                                                </div>
                                                {/* <!--end::Header--> */}

                                                {/* <!--begin::Content--> */}
                                                <div className="tab-content">
                                                    {/* <!--begin::Tabpane--> */}
                                                    <div className="tab-pane active show p-8" id="topbar_notifications_notifications" role="tabpanel">
                                                        {/* <!--begin::Scroll--> */}
                                                        <div className="scroll pr-7 mr-n7" data-scroll="true" data-height="300" data-mobile-height="200">
                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg--> */}
                                                                            <Library />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Cool App</a>
                                                                    <span className="text-muted">Marketing campaign planning</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-warning mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg--> */}
                                                                            <Write />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark-75 text-hover-primary mb-1 font-size-lg">Awesome SAAS</a>
                                                                    <span className="text-muted">Project status update meeting</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg svg-icon-success">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg--> */}
                                                                            <GroupChat />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Claudy Sys</a>
                                                                    <span className="text-muted">Project Deployment & Launch</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-danger mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Attachment2.svg--> */}
                                                                            <Attachment2 />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Trilo Service</a>
                                                                    <span className="text-muted">Analytics & Requirement Study</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-info mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg  svg-icon-info">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg--> */}
                                                                            <ShieldUser />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Bravia SAAS</a>
                                                                    <span className="text-muted">Reporting Application</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-danger mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg  svg-icon-danger">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg--> */}
                                                                            <MailNotification />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Express Wind</a>
                                                                    <span className="text-muted">Software Analytics & Design</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <div className="d-flex align-items-center mb-6">
                                                                {/* <!--begin::Symbol--> */}
                                                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg  svg-icon-success">
                                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Bucket.svg--> */}
                                                                            <Bucket />
                                                                            {/* <!--end::Svg Icon--> */}
                                                                        </span>                    </span>
                                                                </div>
                                                                {/* <!--end::Symbol--> */}

                                                                {/* <!--begin::Text--> */}
                                                                <div className="d-flex flex-column font-weight-bold">
                                                                    <a href="/" className="text-dark text-hover-primary mb-1 font-size-lg">Bruk Fitness</a>
                                                                    <span className="text-muted">Web Design & Development</span>
                                                                </div>
                                                                {/* <!--end::Text--> */}
                                                            </div>
                                                            {/* <!--end::Item--> */}
                                                        </div>
                                                        {/* <!--end::Scroll--> */}

                                                        {/* <!--begin::Action--> */}
                                                        <div className="d-flex flex-center pt-7"><a href="/" className="btn btn-light-primary font-weight-bold text-center">See All</a></div>
                                                        {/* <!--end::Action--> */}
                                                    </div>
                                                    {/* <!--end::Tabpane--> */}

                                                    {/* <!--begin::Tabpane--> */}
                                                    <div className="tab-pane" id="topbar_notifications_events" role="tabpanel">
                                                        {/* <!--begin::Nav--> */}
                                                        <div className="navi navi-hover scroll my-4" data-scroll="true" data-height="300" data-mobile-height="200">
                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-line-chart text-success"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New report has been received
                            </div>
                                                                        <div className="text-muted">
                                                                            23 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-paper-plane text-danger"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            Finance report has been generated
                            </div>
                                                                        <div className="text-muted">
                                                                            25 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-user flaticon2-line- text-success"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New order has been received
                            </div>
                                                                        <div className="text-muted">
                                                                            2 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-pin text-primary"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New customer is registered
                            </div>
                                                                        <div className="text-muted">
                                                                            3 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-sms text-danger"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            Application has been approved
                            </div>
                                                                        <div className="text-muted">
                                                                            3 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-pie-chart-3 text-warning"></i>
                                                                    </div>
                                                                    <div className="navinavinavi-text">
                                                                        <div className="font-weight-bold">
                                                                            New file has been uploaded
                            </div>
                                                                        <div className="text-muted">
                                                                            5 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon-pie-chart-1 text-info"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New user feedback received
                            </div>
                                                                        <div className="text-muted">
                                                                            8 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-settings text-success"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            System reboot has been successfully completed
                            </div>
                                                                        <div className="text-muted">
                                                                            12 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon-safe-shield-protection text-primary"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New order has been placed
                            </div>
                                                                        <div className="text-muted">
                                                                            15 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-notification text-primary"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            Company meeting canceled
                            </div>
                                                                        <div className="text-muted">
                                                                            19 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-fax text-success"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New report has been received
                            </div>
                                                                        <div className="text-muted">
                                                                            23 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon-download-1 text-danger"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            Finance report has been generated
                            </div>
                                                                        <div className="text-muted">
                                                                            25 hrs ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon-security text-warning"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New customer comment recieved
                            </div>
                                                                        <div className="text-muted">
                                                                            2 days ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}

                                                            {/* <!--begin::Item--> */}
                                                            <a href="/" className="navi-item">
                                                                <div className="navi-link">
                                                                    <div className="navi-icon mr-2">
                                                                        <i className="flaticon2-analytics-1 text-success"></i>
                                                                    </div>
                                                                    <div className="navi-text">
                                                                        <div className="font-weight-bold">
                                                                            New customer is registered
                            </div>
                                                                        <div className="text-muted">
                                                                            3 days ago
                            </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {/* <!--end::Item--> */}
                                                        </div>
                                                        {/* <!--end::Nav--> */}
                                                    </div>
                                                    {/* <!--end::Tabpane--> */}

                                                    {/* <!--begin::Tabpane--> */}
                                                    <div className="tab-pane" id="topbar_notifications_logs" role="tabpanel">
                                                        {/* <!--begin::Nav--> */}
                                                        <div className="d-flex flex-center text-center text-muted min-h-200px">
                                                            All caught up!
                <br />
                No new notifications.
            </div>
                                                        {/* <!--end::Nav--> */}
                                                    </div>
                                                    {/* <!--end::Tabpane--> */}
                                                </div>
                                                {/* <!--end::Content--> */}
                                            </form>
                                        </div>
                                        {/* <!--end::Dropdown--> */}
                                    </div>
                                    {/* <!--end::Notifications--> */}





                                    {/* <!--begin::Quick panel--> */}
                                    <div className="topbar-item">
                                        <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
                                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg--> */}
                                                <Layout4Blocks />
                                                {/* <!--end::Svg Icon--> */}
                                            </span>		            </div>
                                    </div>
                                    {/* <!--end::Quick panel--> */}



                                    {/* <!--begin::Languages--> */}
                                    <div className="dropdown">
                                        {/* <!--begin::Toggle--> */}
                                        <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
                                            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                                                <img className="h-20px w-20px rounded-sm" src="/assets/media/svg/flags/260-united-kingdom.svg" alt="" />
                                            </div>
                                        </div>
                                        {/* <!--end::Toggle--> */}

                                        {/* <!--begin::Dropdown--> */}
                                        <div className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
                                            {/* <!--begin::Nav--> */}
                                            <ul className="navi navi-hover py-4">
                                                {/* <!--begin::Item--> */}
                                                <li className="navi-item">
                                                    <a href="/" className="navi-link">
                                                        <span className="symbol symbol-20 mr-3">
                                                            <img src="/assets/media/svg/flags/260-united-kingdom.svg" alt="" />
                                                        </span>
                                                        <span className="navi-text">English</span>
                                                    </a>
                                                </li>
                                                {/* <!--end::Item--> */}

                                                {/* <!--begin::Item--> */}
                                                <li className="navi-item active">
                                                    <a href="/" className="navi-link">
                                                        <span className="symbol symbol-20 mr-3">
                                                            <img src="/assets/media/svg/flags/248-russia.svg" alt="" />
                                                        </span>
                                                        <span className="navi-text">Русский</span>
                                                    </a>
                                                </li>
                                                {/* <!--end::Item--> */}

                                            </ul>
                                            {/* <!--end::Nav--> */}
                                        </div>
                                        {/* <!--end::Dropdown--> */}
                                    </div>
                                    {/* <!--end::Languages--> */}

                                    {/* <!--begin::User--> */}
                                    <div className="topbar-item">

                                        <LoginFunc></LoginFunc>

                                    </div>
                                    {/* <!--end::User--> */}
                                </div>
                                {/* <!--end::Topbar--> */}
                            </div>
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Header--> */}

                        {/* <!--begin::Content--> */}
                        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
                            {/* <!--begin::Subheader--> */}
                            <div className="subheader py-2 py-lg-4  subheader-solid " id="kt_subheader">
                                <div className=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                    {/* <!--begin::Info--> */}
                                    <div className="d-flex align-items-center flex-wrap mr-2">

                                        {/* <!--begin::Page Title--> */}
                                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                                            Dashboard                            </h5>
                                        {/* <!--end::Page Title--> */}

                                        {/* <!--begin::Actions--> */}
                                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>


                                        {/* <!--end::Actions--> */}
                                    </div>
                                    {/* <!--end::Info--> */}

                                    {/* <!--begin::Toolbar--> */}
                                    <div className="d-flex align-items-center">


                                        {/* <!--begin::Daterange--> */}
                                        <a href="/" className="btn btn-sm btn-light font-weight-bold mr-2" id="kt_dashboard_daterangepicker" data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
                                            <span className="text-muted font-size-base font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
                                            <span className="text-primary font-size-base font-weight-bolder" id="kt_dashboard_daterangepicker_date">Aug 16</span>
                                        </a>
                                        {/* <!--end::Daterange--> */}

                                        {/* <!--begin::Dropdowns--> */}
                                        <div className="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">

                                            <a href="/" className="btn btn-sm btn-clean btn-icon" data-toggle="dropdown" aria-expanded="false">
                                                <span className="svg-icon svg-icon-success svg-icon-lg">
                                                    {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg--> */}
                                                    <FilePlus />
                                                    {/* <!--end::Svg Icon--> */}
                                                </span>                </a>
                                            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right py-3">
                                                {/* <!--begin::Navigation--> */}
                                                <ul className="navi navi-hover py-5">
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-drop"></i></span>
                                                            <span className="navi-text">New Group</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-list-3"></i></span>
                                                            <span className="navi-text">Contacts</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                                                            <span className="navi-text">Groups</span>
                                                            <span className="navi-link-badge">
                                                                <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                                            <span className="navi-text">Calls</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-gear"></i></span>
                                                            <span className="navi-text">Settings</span>
                                                        </a>
                                                    </li>

                                                    <li className="navi-separator my-3"></li>

                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-magnifier-tool"></i></span>
                                                            <span className="navi-text">Help</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="/" className="navi-link">
                                                            <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                                                            <span className="navi-text">Privacy</span>
                                                            <span className="navi-link-badge">
                                                                <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                {/* <!--end::Navigation--> */}
                                            </div>
                                        </div>
                                        {/* <!--end::Dropdowns--> */}
                                    </div>
                                    {/* <!--end::Toolbar--> */}
                                </div>
                            </div>
                            {/* <!--end::Subheader--> */}

                            {/* <!--begin::Entry--> */}
                            <div className="d-flex flex-column-fluid">
                                {/* <!--begin::Container--> */}
                                <div className=" container ">
                                    {/* <!--begin::Dashboard--> */}
                                    {/* <!--begin::Row--> */}
                                    <div className="row">

                                        <div className="alert alert-custom alert-white alert-shadow gutter-b fix-width" role="alert">
                                            <InternalTab />
                                        </div>



                                    </div>


                                    {/* <!--end::Row--> */}
                                    {/* <!--end::Dashboard--> */}
                                </div>
                                {/* <!--end::Container--> */}
                            </div>
                            {/* <!--end::Entry--> */}
                        </div>
                        {/* <!--end::Content--> */}

                        {/* <!--begin::Footer--> */}
                        <div className="footer bg-white py-4 d-flex flex-lg-column " id="kt_footer">
                            {/* <!--begin::Container--> */}
                            <div className=" container-fluid  d-flex flex-column flex-md-row align-items-center justify-content-between">
                                {/* <!--begin::Copyright--> */}
                                <div className="text-dark order-2 order-md-1">
                                    <span className="text-muted font-weight-bold mr-2">2021&copy;</span>
                                    <a href="/" className="text-dark-75 text-hover-primary">SM-DP+ Platform</a>
                                </div>
                                {/* <!--end::Copyright--> */}

                                {/* <!--begin::Nav--> */}
                                {/* <!--end::Nav--> */}
                            </div>
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Footer--> */}
                    </div>
                    {/* <!--end::Wrapper--> */}
                    <ModalUserProfile />
                </div>
            </AuthContext.Provider>
        )
    }
}

export default withRouter(withAuth0(App));