import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { Alert, Button, CardBody, CardFooter, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import Select, { ValueType, OptionsType } from "react-select";
import { remote } from '../../util/remote'
import { IUsers, TvendorOption } from '../../interfaces'

export interface IValues {
	EID: string,
	IMEI: string,
	PLMN: string,
	AN: string,
}
export interface IFormState {
	[key: string]: any;
	values: IValues[];
	submitSuccess: boolean;
	loading: boolean;
}

type TCreateUser = Omit<IUsers, "_id" | "cards" | "profiles" | "company">

export const CreateUser = () => {
	const [selectedOption, setSelectedOption] = useState<ValueType<TvendorOption, false> | null>();
	const [user, setUser] = useState<TCreateUser>({
		firstname: "",
		lastname: "",
		login: "",
		phone: "",
		email: "",
		email2: "",
		password: "",
		password2: "",
		vendor: "",
		status: true,
		dtCreate: new Date()
	})
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [allVendors, setAllVendors] = useState<OptionsType<TvendorOption>>([])

	const infoText = "The form was successfully submitted!"
	const [info, setInfo] = useState(infoText)
	const history = useHistory();

	const processFormSubmission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault();
		if (infoRef) {
			infoRef.current?.scrollIntoView(false);
		}
		if (selectedOption) {
			user.vendor = selectedOption.value;
		}
		remote.post(`/users/create`, user).then(
			(result) => {
				setInfo(infoText)
				setSubmitSuccess(true)
				setTimeout(() => {
					history.push('/users/list')
				}, 2500)
			},
			(err) => {
				console.log('scroll')
				infoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
				if (err.response) {
					console.log(err.response.data)
					setInfo(err.response.data.error)
				}
				else {
					setInfo(err.message)
				}
				setSubmitSuccess(true)
			}
		)
		//this.setState({ submitSuccess: true, values: [...this.state.values, formData], loading: false });
	}

	const handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();
		setUser({
			...user,
			[e.currentTarget.name]: e.currentTarget.value
		})
	}

	useEffect(() => {
		const loadData = async () => {
			const vlist = await remote.get(`/vendor/list`)
			setAllVendors(vlist.data.map((el: { _id: string, company: string }) => {
				return {
					value: el._id,
					label: el.company
				}
			}))
		};
		loadData();
		return () => { };
	}, []);

	console.log(user)
	const infoRef = useRef<HTMLDivElement>(null)
	return (
		<Container>
			<Row className={"col-md-12 form-wrapper"}>
				<div ref={infoRef} className="col-md-12">
					{!submitSuccess && (
						<Alert color="primary">
							Fill the form below to create a new user
						</Alert>
					)}
					{submitSuccess && (
						<Alert color="info">
							{info}

						</Alert>
					)}
				</div>
			</Row>
			<Form className="form" onSubmit={(e) => e.preventDefault()}>
				<CardBody>
					<FormGroup row>
						<Col lg={6}>
							<Label for="firstname" className="v-label">First name:</Label>
							<Input
								type="text"
								name="firstname"
								value={user.firstname}
								onChange={handleInputChanges}
								placeholder="Enter first name" />
							<FormText>Please enter your first name</FormText>
						</Col>
						<Col lg={6}>
							<Label for="lastname">Last name:</Label>
							<Input
								type="text"
								name="lastname"
								value={user.lastname}
								onChange={handleInputChanges}
								placeholder="Enter last name" />
							<FormText>Please enter your first name</FormText>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col lg={6}>
							<Label for="phone">Phone number:</Label>
							<Input
								type="tel"
								name="phone"
								value={user.phone}
								onChange={handleInputChanges}
								placeholder="Enter Phone number" />
							<FormText>Please enter your Phone number</FormText>
						</Col>
					</FormGroup>

					<div className="separator separator-dashed my-10"></div>

					<FormGroup row>
						<Col lg={6}>
							<Label for="email">E-mail:</Label>
							<Input
								type="email"
								name="email"
								value={user.email}
								onChange={handleInputChanges}
								placeholder="Enter E-mail" />
							<FormText>Please enter your E-mail</FormText>
						</Col>
						<Col lg={6}>
							<Label for="email">Repeat E-mail:</Label>
							<Input
								type="email"
								name="email2"
								value={user.email2}
								onChange={handleInputChanges}
								placeholder="Repeat E-mail" />
							<FormText>Please repeat your E-mail</FormText>
						</Col>
					</FormGroup>
					<div className="separator separator-dashed my-10"></div>
					<FormGroup row>
						<Col lg={6}>
							<Label for="password">Password:</Label>
							<Input
								type="password"
								name="password"
								value={user.password}
								onChange={handleInputChanges}
								placeholder="Enter Password" />
							<FormText>Please enter your password</FormText>
						</Col>
						<Col lg={6}>
							<Label for="email">Repeat Password:</Label>
							<Input
								type="password"
								name="password2"
								value={user.password2}
								onChange={handleInputChanges}
								placeholder="Repeat Password" />
							<FormText>Please repeat your password</FormText>
						</Col>
					</FormGroup>

					<div className="separator separator-dashed my-10"></div>

					<FormGroup row>
						<Col lg={6}>
							<label className="col-1 col-form-label">Status</label>
							<div className="col-1">
								<span className="switch switch-sm switch-icon top-m-fix-switch">
									<label>
										<input type="checkbox" name="select" />
										<span></span>
									</label>
								</span>
							</div>
						</Col>
						<Col lg={6}>
							<Label for="vendorlist">Vendors:</Label>{' '}
							<Select
								defaultValue={selectedOption}
								onChange={setSelectedOption}
								options={allVendors}
							/>
						</Col>
					</FormGroup>
					<div className="separator separator-dashed my-10"></div>
					<FormGroup row>
						<div className="image-input image-input-empty image-input-outline" id="kt_image_5">
							<div className="image-input-wrapper"></div>

							<label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
								<i className="fa fa-pen icon-sm text-muted"></i>
								<input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
								<input type="hidden" name="profile_avatar_remove" />
							</label>

							<span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
								<i className="ki ki-bold-close icon-xs text-muted"></i>
							</span>

						</div>

					</FormGroup>
				</CardBody>

				<CardFooter>
					<Row>
						<Col lg={6}>
							<Button type="reset" color="primary" onClick={processFormSubmission}>Save</Button>
							<Button type="reset" color="secondary" onClick={() => history.goBack()}>Cancel</Button>
						</Col>
						<Col lg={6} className="text-right">
							<Button type="reset" color="danger">Delete</Button>
						</Col>
					</Row>
				</CardFooter>
			</Form>

		</Container>
	)
}

export default CreateUser