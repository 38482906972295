import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; 
import * as serviceWorker from './serviceWorker';

import { Auth0Provider, AppState} from "@auth0/auth0-react";
import config from "./auth_config.json";
import history from "./util/history";


const onRedirectCallback = (appState: AppState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

ReactDOM.render(
    <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        audience={config.audience}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >

        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Auth0Provider>
    , document.getElementById('root')
);
serviceWorker.unregister();