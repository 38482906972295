import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import axios from 'axios';
import { backend } from '../../interfaces'

export interface IValues {
    [key: string]: any;
}
export interface IFormState {
    eid: number,
    esim: any;
    values: IValues[];
    submitSuccess: boolean;
    loading: boolean;
}

class EditCard extends React.Component<RouteComponentProps<any>, IFormState> {
    constructor(props: RouteComponentProps) {
        super(props);
        console.log(props)
        this.state = {
            eid: this.props.match.params.eid,
            esim: {},
            values: [],
            loading: false,
            submitSuccess: false,
        }
    }
    public componentDidMount(): void {
        axios.get(`${backend}/${this.state.eid}`).then(data => {
            this.setState({ esim: data.data[0] });
        })
    }

    private processFormSubmission = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        this.setState({ loading: true });
        axios.patch(`${backend}/${this.state.eid}`, this.state.values).then(data => {
            this.setState({ submitSuccess: true, loading: false })
            setTimeout(() => {
                this.props.history.push('/');
            }, 1500)
        })
    }

    private setValues = (values: IValues) => {
        this.setState({ values: { ...this.state.values, ...values } });
    }
    private handleInputChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        console.log(e.currentTarget)
        this.setValues({ [e.currentTarget.id]: e.currentTarget.value })
    }

    public render() {
        const { submitSuccess, loading } = this.state;
        return (
            <div className="App">
                {this.state.esim &&
                    <div>
                        < h1 > eSim Management App</h1>

                        <div>
                            <div className={"col-md-12 form-wrapper"}>
                                <h2> Edit Card </h2>
                                {submitSuccess && (
                                    <div className="alert alert-info" role="alert">
                                        Card's details has been edited successfully </div>
                                )}
                                <form id={"create-post-form"} onSubmit={this.processFormSubmission} noValidate={true}>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="ALIAS"> NickName </label>
                                        <input type="text" id="ALIAS" defaultValue={this.state.esim.ALIAS} onChange={(e) => this.handleInputChanges(e)} name="ALIAS" className="form-control" placeholder="ALIAS" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="EID"> EID </label>
                                        <input type="text" readOnly id="EID" defaultValue={this.state.esim.EID} onChange={(e) => this.handleInputChanges(e)} name="EID" className="form-control" placeholder="EID" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="IMEI"> IMEI </label>
                                        <input type="text" id="IMEI" defaultValue={this.state.esim.IMEI} onChange={(e) => this.handleInputChanges(e)} name="IMEI" className="form-control" placeholder="IMEI" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="PLMN"> PLMN </label>
                                        <input type="text" id="PLMN" defaultValue={this.state.esim.PLMN} onChange={(e) => this.handleInputChanges(e)} name="PLNM" className="form-control" placeholder="PLMN" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="AN"> sasAcreditationNumber </label>
                                        <input type="text" id="AN" defaultValue={this.state.esim.AN} onChange={(e) => this.handleInputChanges(e)} name="AN" className="form-control" placeholder="sasAcreditationNumber" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="dtCreate"> dtCreate </label>
                                        <input type="datetime-local" readOnly value={this.state.esim.dtCreate || 0} name="dtCreate" className="form-control" />
                                    </div>
                                    <div className="form-group col-md-4 pull-right">
                                        <button className="btn btn-success" type="submit">
                                            Edit Card </button>
                                        {loading &&
                                            <span className="fa fa-circle-o-notch fa-spin" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default withRouter(EditCard)