import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginFunc = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();



    //console.log(user);
    if (isAuthenticated) {
        return (
            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{user?.nickname}</span>
                <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                </span>
            </div>
        );
    }
    else {
        return (
            <div onClick={(e) => { e.preventDefault(); loginWithRedirect() }} className="" id="kt_quick_user_toggle">
                <button className="btn btn-outline-primary">
                    <i className="fas fa-sign-in-alt"></i> Login
          </button>
            </div>
        )
    }
}