import React, {useState, useContext, useEffect, useMemo} from 'react';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Col, Container, Input, Label, Row, Table } from 'reactstrap';
import ProfileButton from '../cards/ProfileButton'
import { ProfileRows } from '../cards/ProfileRow'
import { ListPagination, ListPageInfo } from '../ListPagination'
import ListSize from '../ListSize'
import { remote } from '../../util/remote';
import { IState, IICCxProfile, ICType, ICommands, ICard, TvendorOption } from '../../interfaces'
import ModalSendProfile from "../ModalSendProfile"
import { AuthContext } from "../../util/authcontext"
import { PageSizeContext } from "../../util/pagesizecontext"
import { makeApiListRoute } from "../../util/util"
import SelectVendor from '../eids/SelectVendor';
import { OptionsType } from 'react-select/src/types';

interface IxState extends IState {
    profiles: IICCxProfile[];
    filter: string;
    isLoading: boolean
}

interface IxProps {
    mainHref: string;
    token: boolean;
}

interface IvState extends Omit<IxState, "filter"> {
    profileRow(e: React.MouseEvent<HTMLButtonElement>): void;
    cardRefresh(eid: string): void;
    profileDownload(eid: string, url: string): void;
    deletecard(id: string): void;
    activateProfile(e: React.MouseEvent<HTMLButtonElement>): void;
    searchFilter(inp: string): void;
    pager: JSX.Element;
    pageInfo: JSX.Element;
    refreshData?: () => void
}

const List = () => {
    const auth = useContext(AuthContext)
    const { pageSize } = useContext(PageSizeContext)
    const { page, onboard } = useParams<{ page: string, onboard: string }>()
    const listRoute = makeApiListRoute("/list", page, onboard, pageSize)
    //console.log(page, onboard, listRoute)
    /*     const addSearch = (filter: string) => {
            history.replace(`/list/filter/${filter}`);
        } */
    return (
        <NewList
            key={auth.user}
            token={auth.token}
            mainHref={'/eids/list'}
            listRoute={listRoute}
        ></NewList>
    )
}

export default List;

type TEidsListProps = { listRoute: string}

export class NewList extends React.Component<IxProps & TEidsListProps, IxState> {
    public interval: number = 0;
    private totalCards: number = 0;
    constructor(props: IxProps & TEidsListProps) {
        super(props);

        this.state = {
            cards: null,
            profiles: [],
            filter: "",
            isLoading: false
        }
        this.profileRow = this.profileRow.bind(this)
        this.activateProfile = this.activateProfile.bind(this)
        this.cardRefresh = this.cardRefresh.bind(this)
        this.searchFilter = this.searchFilter.bind(this)
        this.deletecard = this.deletecard.bind(this)
        this.getCards = this.getCards.bind(this)
    }
    private profileMonitor(): void {
        this.interval = window.setInterval(() => {
            const profiles = this.state.profiles.filter((el: IICCxProfile) => el.hidden === false)
            if (profiles.length < 1) return;
            this.getProfiles(profiles[0].eid)
        }, 2 * 1000)
    }
    public componentWillUnmount() {
        clearInterval(this.interval);
    }
    public async componentDidMount(): Promise<void> {
        console.log("mount", this.props.listRoute, this.props.token)

        if (this.props.token) {
            this.getCards();
        }
        this.profileMonitor();
    }
    public async componentDidUpdate(prevProps: IxProps & TEidsListProps) {
        if (prevProps.listRoute !== this.props.listRoute) {
            this.getCards();
        }
    }
    public getCards(): void {
        this.setState({ isLoading: true })
        remote.get(this.props.listRoute)
            .then(data => {
                //this.setState({ cards: data.data })
                const rHeaders = new Headers(data.headers);
                this.totalCards = rHeaders.has('x-cards-total') ? parseInt(rHeaders.get('x-cards-total') || "0") : 0;
                return data
            })
            .then(data => this.setState({ cards: data.data, isLoading: false }))
    }
    public searchFilter(inp: string): void {
        if (inp.length > 2) {
            //this.setState({ filter: inp })
            //this.props.addSearch(inp)
            /*             remote.get(`/list/filter/${inp}`)
                            .then(data => {
                                this.setState({ cards: data.data })
                            }) */
        }
        else if (inp.length === 0) {
            this.getCards();
        }
    }

    public deletecard(id: string): void {
        console.log(id);

        remote.del(`/${id}`)
            .then((data) => {
                this.setState(prev => {

                    const cards: ICard[] | null = prev.cards ? prev.cards
                        .filter((el: ICard) => el["EID"] !== id)
                    : null
                    return { cards };
                })
            },
                (err) => console.log(err)
            )
    }

    public profileRow(e: React.MouseEvent<HTMLButtonElement>): void {
        //debugger;
        //const cards = this.state.cards;
        const eid: string = e.currentTarget.dataset.eid ? e.currentTarget.dataset.eid : "";
        console.log(`click! ${eid}`)
        const profiles = this.state.profiles;
        const existing: boolean = profiles.findIndex(el => el.eid === eid) > -1;
        if (existing) {
            this.hideProfile(eid);
        }
        else if (eid) {
            //const card = cards.filter((el) => el.EID === eid)[0];
            this.getProfiles(eid)
        }
    }

    private hideProfile(eid: string): void {
        this.setState(prev => {
            const profiles: IICCxProfile[] = prev.profiles
                .filter((el: IICCxProfile) => el["eid"] !== eid)
            return { profiles };
        })
    }

    private toggleProfile(eid: string): void {
        this.setState(prev => {
            const profiles: IICCxProfile[] = prev.profiles
                .map((el: IICCxProfile) => {
                    if (el["eid"] === eid) {
                        el["hidden"] = !el["hidden"];
                    }
                    return el;
                })
            return { profiles };
        })
    }

    private profileDownload(eid: string, url: string): void {

        remote.post(`/profiles/download`, { eid, url }).then(data => {
            /* this.setState(prev => {
                const cards = prev.cards
                .map((card) => {
                    if(card.EID === eid) return data.data[0]
                    return card;
                })
                return { cards }
            }) */
            console.log(data)
        })
    }

    public cardRefresh(eid: string): void {
        remote.get(`/${eid}`).then(data => {
            this.setState(prev => {

                const cards = prev.cards ? prev.cards
                    .map((card) => {
                        if (card.EID === eid) return data.data[0]
                        return card;
                    })
                    : null
                return { cards }
            })
        })
    }

    private getProfiles(eid: string): void {
        remote.get(`/profiles/${eid}`)
            .then(data => {
                this.setState(prev => {
                    const profiles = prev.profiles
                        .filter((el: IICCxProfile) => el["eid"] !== eid)
                        .concat(
                            data.data.map((el: IICCxProfile) => {
                                el["eid"] = eid;
                                el["hidden"] = false;
                                return el;
                            }));
                    return { profiles };
                })
            })
    }

    public activateProfile(e: React.MouseEvent<HTMLButtonElement>): void {

        const eid: string = e.currentTarget.dataset.eid ? e.currentTarget.dataset.eid : "";
        const iccid: string = e.currentTarget.dataset.iccid ? e.currentTarget.dataset.iccid : "";
        const type: ICType = parseInt(e.currentTarget.value);
        const command: string = (() => {
            let suffix: string = "";
            switch (type) {
                case 0:
                    suffix = "enable";
                    break;
                case 1:
                    suffix = "disable";
                    break;
                case 2:
                    suffix = "delete";
                    break;
            }
            return suffix;
        })()

        //console.log(eid,iccid);
        remote.post(`/profiles/${command}`, { eid, iccid }).then(data =>
            this.setState(prev => {
                const cmd = data.data;
                const profiles: IICCxProfile[] = prev.profiles
                    .map((el: IICCxProfile) => this.cmd2profile(el, eid, iccid, cmd, type))
                return { profiles };
            }),
            err => console.log(err)
        )
    }

    private cmd2profile = (el: IICCxProfile, eid: string, iccid: string, cmd: ICommands, type: ICType) => {
        if (el["eid"] === eid && el["ICCID"] === iccid) {
            if (el.activateCmd) {
                if (isEmpty(cmd)) {
                    el.activateCmd = el.activateCmd.filter((t) => t.type !== type)
                }
                else {
                    el.activateCmd.push(cmd);
                }
            }
            else {
                el.activateCmd = [cmd]
            }
        }
        console.log(el)
        return el;
    }
    private pager = () => {

        return (
            <ListPagination
                mainHref={this.props.mainHref}
                total={this.totalCards}
            />
        )
    }
    private pageInfo = () => {

        return (
            <ListPageInfo
                total={this.totalCards}
            />
        )
    }
    public render() {
        return (
            <ListView
                refreshData={this.getCards}
                isLoading={this.state.isLoading}
                cards={this.state.cards}
                profiles={this.state.profiles}
                profileRow={this.profileRow}
                cardRefresh={this.cardRefresh}
                profileDownload={this.profileDownload}
                deletecard={this.deletecard}
                activateProfile={this.activateProfile}
                searchFilter={this.searchFilter}
                pager={this.pager()}
                pageInfo={this.pageInfo()}
            ></ListView>
        )
    }
}

const ListView = (props: IvState) => {
    const cards = props.cards;
    const refreshData = props.refreshData
    const profiles = props.profiles;
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const auth = useContext(AuthContext)

    const [allVendors, setAllVendors] = useState<OptionsType<TvendorOption>>([])

    useEffect(() => {
        const loadData = async () => {
            if (auth.token) {
                const vlist = await remote.get(`/vendor/list`)
                setAllVendors(vlist.data.map((el: { _id: string, company: string }) => {
                    return {
                        value: el._id,
                        label: el.company
                    }
                }))
            }
        };
        loadData();
        return () => { };
    }, [auth]);

    useEffect(() => {
        setIsLoading(props.isLoading)
    }, [props.isLoading])

    const hist = useHistory();
    const loc = useLocation();
    const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        const val = e.target.value;
        if (val.length > 2) hist.push(`${loc.pathname}?filter=${e.target.value}`)
        else hist.push(loc.pathname)
    }
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const checkCard = (eid: string): void => {
        const newSel = [...selectedCards]
        if (selectedCards.indexOf(eid) > -1) {
            newSel.splice(newSel.findIndex(item => item === eid), 1)
        }
        else newSel.push(eid)
        setSelectedCards(newSel)
    }
    // const setUsersEnable = selectedCards.length === 1;
    // const setUsers = (list: string[]) => {
    //     console.log(list, selectedCards)
    //     setSelectedCards([])
    // }


    const renderedPreloader = useMemo(() => {
        if (!isLoading && cards) return null
        return (
            <tr className="text-center">
                {/*<td colSpan={7}><h2>No cards found at the moment!</h2></td>*/}
                <td colSpan={7}><h2>Loading...</h2></td>
            </tr>
        )
    }, [isLoading, cards])

    const changeVendor = async (v: TvendorOption, uid: string[]): Promise<void> => {

        const res = await remote.patch(`/vendor/${v.value}/eid`, { eidIds: uid })
        console.log(res)
        if (refreshData) refreshData()

    }

    const renderedNoContent = useMemo(() => {
        if (isLoading || !cards || (cards && cards.length > 0)) return null
        return (
            <tr className="text-center">
                {/*<td colSpan={7}><h2>No cards found at the moment!</h2></td>*/}
                <td colSpan={7}><h2>No EID's found</h2></td>
            </tr>
        )
    }, [isLoading, cards])

    return (

        <Container>
            <div id="kt_datatable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <Row className="fix-pads">
                    <Col sm="12" md="3">
                        <ListSize />
                    </Col>
                    <Col sm="12" md="2">
                        {/* <NavLink hidden={false} to={"/eids/list/3"} >test</NavLink> */}
                    </Col>
                    <Col sm="12" md="2">
                        {/* <ModalCardsUsers disabled={!setUsersEnable} submitFn={setUsers} /> */}

                    { selectedCards && selectedCards.length !== 0 && <SelectVendor
                    // @ts-ignore
                    u={selectedCards} submitFn={changeVendor} vlist={allVendors} />}

                    </Col>
                    <Col sm="12" md="5">
                        <div id="kt_datatable_filter" className="dataTables_filter">
                            <Label >Search:<Input type="search" name="search" id="search" value={search} onChange={changeSearch}>
                            </Input>
                            </Label>
                        </div>
                    </Col>
                </Row>
            </div>


            <div className="row">
                <Table responsive>
                    {/* <table className="table table-separate table-head-custom collapsed table-fix compact search-fix" id="ekt_datatable"> */}
                    <thead className="thead-light">
                        <tr>
                            <th className="table-checkbox-width">
                                <CardCheckBox checked={false} chFn={() => { }} />
                            </th>
                            {/* <th className="table-checkbox-width"><div className="table-check-box-fix"><label className="checkbox checkbox-single"><input type="checkbox" value="1" />&nbsp;<span></span></label></div></th> */}
                            <th className="table-expand-width"></th>
                            <th>Alias / EID</th>
                            <th>IMEI / PLMN</th>
                            <th>Timestamp</th>
                            <th>Device actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cards && cards.length !== 0 && cards.map((card, idx) =>
                            <React.Fragment key={`f${idx}`}>
                                <tr key={idx} className={card.IMEI ? "" : "noImei"} style={{ borderBottom: "1px solid #ebedf3" }}>
                                    <td><CardCheckBox checked={selectedCards.indexOf(card.EID) > -1} chFn={() => checkCard(card.EID)} /></td>
                                    <ProfileButton eid={card.EID} onClick={props.profileRow} />
                                    <td><div className="cardallias">{card.ALIAS}</div><div className="cardeid">{card.EID}</div></td>
                                    <td><div className="cardallias">{card.IMEI}</div><div className="cardallias">{card.PLMN}</div></td>
                                    <td>{new Date(card.dtCreate).toLocaleString('de-DE')}</td>
                                    <td rowSpan={1} >
                                        <div className="d-flex justify-content-between align-items-center width-icons-fix">
                                            <button className="btn btn-sm btn-clean btn-icon" title="Refresh device" onClick={() => props.cardRefresh(card.EID)}><i className="fas fa-sync-alt"></i></button>
                                            <ModalSendProfile buttonLabel="Send" submitFn={(url) => props.profileDownload(card.EID, url)} className="modal-dialog modal-dialog-centered" />
                                            {/* <button className="btn btn-sm btn-clean btn-icon no-bg" title="Download profile via QR" onClick={() => props.profileDownload(card.EID)}><i className="fas fa-qrcode"></i></button> */}
                                            <button className="btn btn-sm btn-clean btn-icon" title="Delete device" onClick={() => props.deletecard(card.EID)}><i className="fas fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <ProfileRows
                                    key={`p${idx}`}
                                    profiles={profiles.filter((el) => {
                                        return el.eid === card.EID && !el.hidden
                                    })}
                                    activate={props.activateProfile}
                                    forEid={card.EID}
                                />
                            </React.Fragment>
                        )}
                        {renderedNoContent}
                        {renderedPreloader}
                    </tbody>
                    <tfoot className="thead-light">
                        <tr>
                            <th colSpan={2}></th>
                            <th>Alias / EID</th>
                            <th>IMEI / PLMN</th>
                            <th>Timestamp</th>
                            <th>Profile actions</th>
                            {/* <th scope="col">PLMN</th>
                                    <th scope="col">Timestamp</th>
                                    <th scope="col">Actions</th> */}
                        </tr>
                    </tfoot>
                </Table>
            </div>
            <div className="row fix-pads">
                <div className="col-sm-12 col-md-5">
                    {props.pageInfo}
                </div>
                <div className="col-sm-12 col-md-7">
                    {props.pager}
                </div>
            </div>
        </Container>


    )
}

//XXX Invalid
export const ActiveToggler = () => {
    const { onboard } = useParams<{ onboard: string }>()
    const isOnboard: string = onboard ? "All" : "Active"
    const listRoute = `/eids/list/${onboard ? "" : "onboard"}`

    console.log(onboard, isOnboard, listRoute)
    return (
        <NavLink to={listRoute}>{isOnboard}</NavLink>
    )
}

interface actionProps extends React.FunctionComponent {
    getProfiles(): void;
    profileDownload(): void;
    deletecard(): void;
}

// eslint-disable-next-line
const CardActions = (props: actionProps) => {
    return (
        <td>
            <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group" style={{ marginBottom: "20px" }}>
                    <button className="btn btn-sm btn-outline-secondary" onClick={props.getProfiles}>Refresh</button>
                    <button className="btn btn-sm btn-outline-secondary" onClick={props.profileDownload}>Download profile</button>
                    <button className="btn btn-sm btn-outline-secondary" onClick={props.deletecard}>Delete card</button>
                </div>
            </div>
        </td>
    )
}

interface ICardCheckBox {
    chFn(): void;
    checked: boolean;
}

const CardCheckBox = (props: ICardCheckBox) => {
    const changeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.chFn();
    }
    return (
        <div>
            <Label >
                <Input checked={props.checked} type="checkbox" onChange={changeCheck} />&nbsp;<span></span>
            </Label>
        </div>
    )
}
