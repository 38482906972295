import React, { useContext } from "react"
import { PageSizeContext } from "../util/pagesizecontext"

const ListSize = () => {
    //const [pageSize, setPageSize] = useState("10")
    const { pageSize, pSizeDispatch } = useContext(PageSizeContext)

    const options = ["10", "25", "50", "100"].map((opt, idx) => {
        return (
            <option key={idx} value={opt}>{opt}</option>
        )
    })
    const selectPageSize = (e: React.FormEvent<HTMLSelectElement>) => {

        pSizeDispatch({ type: 'set', size: e.currentTarget.value })
    }
    //console.log(pageSize)
    return (
        <div className="dataTables_length" id="kt_datatable_length">
            <label>Show
    <select
                    name="kt_datatable_length"
                    aria-controls="kt_datatable"
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={selectPageSize}
                    defaultValue={pageSize}
                >
                    {options}
                </select> entries</label>
        </div>
    )
}

export default ListSize