import { useContext } from "react"
import { Pagination, PaginationItem } from 'reactstrap';
import { TListPaginationProps, TListPageInfoProps } from "../interfaces"
import { NavLink, useParams } from "react-router-dom"
import { PageSizeContext } from "../util/pagesizecontext"


export const ListPagination = (props: TListPaginationProps) => {
    const { pageSize } = useContext(PageSizeContext)
    const { page } = useParams<{ page: string }>()
    const pageNum: number = page ? parseInt(page) : 1
    const sizeNum: number = pageSize ? parseInt(pageSize) : 10
    const base = window.location.origin;
    const countPages = Math.ceil(props.total / sizeNum)
    const firstLink: string | undefined = ((): string | undefined => {
        if (pageNum < 3) return;
        const link = new URL(props.mainHref, base)
        return link.pathname;
    })()
    const prevLink: string | undefined = ((): string | undefined => {
        if (pageNum < 2) return;
        const link = new URL(`${props.mainHref}/${pageNum - 1}`, base)
        return link.pathname;
    })()

    const nextLink: string | undefined = ((): string | undefined => {
        if (pageNum === countPages) return;
        const link = new URL(`${props.mainHref}/${pageNum + 1}`, base)
        return link.pathname;
    })()

    const activePage = new URL(`${props.mainHref}/${pageNum}`, base)

    //console.log(countPages, props.total, pageNum)
    return (
        <Pagination aria-label="Page navigation">
            {firstLink &&
                <PaginationItem>
                    <NavLink className="page-link" to={firstLink}>
                        <span aria-hidden="true">«</span><span className="sr-only">First</span>
                    </NavLink>
                </PaginationItem>
            }
            {prevLink &&
                <PaginationItem>
                    <NavLink className="page-link" to={prevLink}>
                        <span aria-hidden="true">‹</span>
                    </NavLink>
                </PaginationItem>
            }
            {prevLink &&
                <PaginationItem>
                    <NavLink className="page-link" to={prevLink}>
                        {pageNum - 1}
                    </NavLink>
                </PaginationItem>
            }
            <PaginationItem active>
                <NavLink className="page-link" to={activePage.pathname}>
                    {pageNum}
                </NavLink>
            </PaginationItem>
            {nextLink &&
                <PaginationItem>
                    <NavLink className="page-link" to={nextLink}>
                        {pageNum + 1}
                    </NavLink>
                </PaginationItem>
            }
            {nextLink &&
                <PaginationItem>
                    <NavLink className="page-link" to={nextLink}>
                        <span aria-hidden="true">›</span><span className="sr-only">Next</span>
                    </NavLink>
                </PaginationItem>
            }
            {countPages > pageNum &&
                <PaginationItem>
                    <NavLink className="page-link" to={`${props.mainHref}/${countPages}`}>
                        <span aria-hidden="true">»</span><span className="sr-only">Last</span>
                    </NavLink>
                </PaginationItem>
            }
        </Pagination>

    )
}

export const ListPageInfo = (props: TListPageInfoProps) => {
    const { pageSize } = useContext(PageSizeContext)
    const { page } = useParams<{ page: string }>()
    const pageNum: number = page ? parseInt(page) : 1
    const sizeNum: number = pageSize ? parseInt(pageSize) : 10
    const from: number = pageNum * sizeNum - sizeNum + 1;
    const countPages = Math.ceil(props.total / sizeNum)
    const to: number = pageNum < countPages ? pageNum * sizeNum : props.total;

    return (
        <div className="dataTables_info" id="kt_datatable_info">Showing {from} to {to} of {props.total} entries</div>
    )
}

export default ListPagination