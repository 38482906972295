import React, { useState, PropsWithoutRef } from 'react';
// eslint-disable-next-line
import { Button, Col, Form, FormGroup, FormFeedback, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { IVendor, TaddResult } from '../interfaces'
import { remote } from '../util/remote';

type Mcb = {
    cb(data: TaddResult): void;
}
type Mprops = PropsWithoutRef<IVendor & Mcb>

const ModalAddVendorEids = (props: Mprops) => {
    /*   const {
      } = props;
     */
    const [modal, setModal] = useState(false);
    const [start, setStart] = useState<string>("");
    const [count, setCount] = useState<string>("");
    const changeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.validity.valid)
            setStart(e.target.value)
    }
    const startValid = start.length === 32 ? true : false;
    const changeCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.validity.valid)
            setCount(e.target.value)
    }
    const countValid = (() => {
        let valid = false;
        try {
            if (parseInt(count) > 0)
                valid = true
        }
        catch (e) {

        }
        return valid
    })()

    const submit = () => {
        console.log(start, count)
        if (start) {
            remote.post(`/vendor/${props._id}/eids`, { start, count }).then(
                (result) => {
                    //console.log(result)
                    const data: TaddResult = result.data;
                    if (result.status === 200) {
                        toggle()
                        props.cb(data)
                    }
                    console.log(data.success)
                },
                (err) => {
                    console.log(err)
                }
            )
        }
    };
    const toggle = () => setModal(!modal);

    //console.log(start, start.length, startValid)
    return (
        <React.Fragment>
            <Button size="sm" className="btn-clean btn-icon" title="Add EIDs to Vendor" onClick={toggle}><i className="fas fa-sim-card"></i></Button>{' '}
            <Modal isOpen={modal} toggle={toggle} className="">
                <ModalHeader toggle={toggle}>Add EID's for {props.company}:</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <Label for="start">Start:</Label>{' '}
                            <Input
                                valid={startValid}
                                invalid={!startValid}
                                type="tel"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                maxLength={32}
                                value={start}
                                name="start"
                                placeholder={"0".repeat(32)}
                                onChange={changeStart}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="count">Count:</Label>{' '}
                            <Input
                                valid={countValid}
                                invalid={!countValid}
                                type="tel"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                maxLength={10}
                                value={count}
                                name="count"
                                placeholder={"0".repeat(10)}
                                onChange={changeCount}
                            />
                        </FormGroup>
                    </Form>
                    {/*                     <Row>
                    <FormFeedback valid>Sweet! that name is available</FormFeedback>
                        <Col  sm="12" md={{ size: 2, offset: 5 }}>
                            <Spinner style={{ width: '3rem', height: '3rem', visibility: "visible" }} />{' '}
                        </Col>
                    </Row> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="primary" onClick={submit}>Add</Button>{' '}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default ModalAddVendorEids;