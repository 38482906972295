export const makeApiListRoute = (path: string, page: string, onboard: string, psize: string): string => {

    let route: string = path;
    if (parseInt(page) > 0) route += `/${parseInt(page).toString()}`
    else route += `/1`;
    if (onboard) route += `/1`
    else route += `/0`
    if (parseInt(psize) > 0) route += `/${parseInt(psize).toString()}`
    else route += `/10`

    return route;
}

export const getFilter = (s: URLSearchParams) => {
    const result = new URLSearchParams();
    return ["eid", "imei", "filter"].reduce((result, type) => {
        const filter = s.get(type) || "";
        if (filter.length > 2) result.append(type, filter);
        return result;
    }, result)
}