import React from "react"
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!

const auth = {
    user: "guest",
    token: false
}

export const AuthContext = React.createContext(
    auth
);