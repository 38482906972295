import * as React from 'react';
import { IICCxProfile, ICType } from '../../interfaces'

interface Props {
    profiles: IICCxProfile[];
    activate: (e: React.MouseEvent<HTMLButtonElement>) => void;
    forEid: string;
    show: boolean;
}

interface rowProps {
    profile: IICCxProfile;
    activate: (e: React.MouseEvent<HTMLButtonElement>) => void;
    forEid: string;
}


const waiting4Command = (cmd: ICType, p: IICCxProfile): boolean => {
    if (p.activateCmd) {
        return p.activateCmd.findIndex((c) => c.type === cmd) > -1;
    }
    return false;
}


export const LogRow = (props: rowProps) => {
    const profile = props.profile;
    const profileClass: string = ((p) => {
        if (p.profileState === '01') {
            return "datatable-row activeProfile";
        }
        else if (waiting4Command(ICType.EnableProfile, profile)) {
            return "datatable-row activateProfile";
        }
        else {
            return "datatable-row";
        }
    })(profile)

    //return null;
    return (
        <tr className={profileClass+" sub-table-fix"}>
			<td></td>
            <td className="datatable-cell">{
                // @ts-ignore
            profile.message
            }</td>
        </tr>
    )
}



export const RequestRow = (props: rowProps) => {
    const profile = props.profile;
    const profileClass: string = ((p) => {
        if (p.profileState === '01') {
            return "datatable-row activeProfile";
        }
        else if (waiting4Command(ICType.EnableProfile, profile)) {
            return "datatable-row activateProfile";
        }
        else {
            return "datatable-row";
        }
    })(profile)

    //return null;
    return (
        <tr className={profileClass+" sub-table-fix"}>
			<td></td>
            <td className="datatable-cell">
            <span style={{ 
    display:'block',
    maxWidth:'1100px',
    wordWrap:'break-word' }}>{
                // @ts-ignore
            profile.requestBody
            }</span>
            </td>
        </tr>
    )
}

export const LogsRows = (props: Props) => {

    const profiles: IICCxProfile[] = props.profiles;
    //{props.profiles && props.profiles.map((profile,idx) =>
    if (!profiles.length || ! props.show) return null;
    return (
        <tr className="datatable-row-subtable datatable-row-subtable-even">
            <td className="datatable-subtable" colSpan={7}>
                <table className="table table-separate table-head-custom collapsed compact sub-table-fix">
						<thead className="thead-light">
                                <tr>
									<th className="sub-tr-fix"></th>
                                    <th scope="col">Error message</th>
                                </tr>
                            </thead>
                    <tbody>
                        {profiles.map((profile, idx) =>
                            <LogRow
                                key={`pr${idx}`}
                                profile={profile}
                                activate={props.activate}
                                forEid={props.forEid}
                            />
                        )}
                    </tbody>
                </table>
                <table className="table table-separate table-head-custom collapsed compact sub-table-fix">
						<thead className="thead-light">
                                <tr>
									<th className="sub-tr-fix"></th>
                                    <th scope="col">Requst body (failed)</th>
                                </tr>
                            </thead>
                    <tbody>
                        {profiles.map((profile, idx) =>
                            <RequestRow
                                key={`pr${idx}`}
                                profile={profile}
                                activate={props.activate}
                                forEid={props.forEid}
                            />
                        )}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}
