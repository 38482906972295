import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class Test extends React.Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        //const { submitSuccess, loading } = this.state;
        return (
            <div>
                <div className={"col-md-12 form-wrapper"}>
                    <h2> IG provides everything you need to securely connect and manage your IoT.</h2>
					<br />
					<div className={"separator separator-dashed"}></div>
					<br />
<ul className={"list-unstyled font-size-h4"}>
    <li>A full range of solutions and services for
        <ul>
            <li>IoT connectivity</li>
            <li>Security</li>
			<li>Device management</li>
        </ul>
    </li>
</ul>

                </div>
				
				
				
            </div>
			
			
			
			
        )
    }
}

export default withRouter(Test)