import React from "react";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import {
    Library,
    Compiling,
    PenAndRuller,
    Equalizer,
    Select,
    Box2,
    User,
    SignOut,
} from '../img/icons/';

export const MenuItems = () => {
    const {
        logout,
    } = useAuth0();
    const logoutWithRedirect = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logout({
            returnTo: window.location.origin,
        });
    }
    return (
        <ul className="menu-nav ">
            <li className="menu-section ">
                <h4 className="menu-text">Management</h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>


            <li className="menu-item menu-item-active" >
                <NavLink
                    className="menu-link"
                    to={"/"}
                >
                    <span className="svg-icon menu-icon">
                        {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg--> */}
                        <Library />
                        {/* <!--end::Svg Icon--> */}
                    </span><span className="menu-text">Dashboard</span>
                </NavLink>
            </li>

            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" onClick={() => false} className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg--> */}
                <Compiling />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Scripts</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Add new Script</span></a></li>
                    </ul>
                </div>
            </li>


            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" onClick={() => false} className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg--> */}
                <Compiling />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">EID's Groups</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Create new Group</span></a></li>
                    </ul>
                </div>
            </li>


            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" onClick={() => false} className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg--> */}
                <PenAndRuller />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Campaigns</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Create new Campaign</span></a></li>
                    </ul>
                </div>
            </li>


            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover">
                <a href="/" onClick={() => false} className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                        {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Select.svg--> */}
                                    <Equalizer />
                        {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-text">Logs</span><i className="menu-arrow"></i>
                </a>
                <div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/logs/list"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>

            <li className="menu-section ">
                <h4 className="menu-text">Administration</h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>

            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover">
                <a href="/" onClick={() => false} className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                        {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Select.svg--> */}
                        <Select />
                        {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-text">EID's</span><i className="menu-arrow"></i>
                </a>
                <div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/eids/list"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>

            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" onClick={() => false} className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Select.svg--> */}
                <Select />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Profiles</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Generate profile</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Master template</span></a></li>
                    </ul>
                </div>
            </li>

            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg--> */}
                <Compiling />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Vendors</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/vendor/list"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span>
                            </NavLink>
                        </li>
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/vendor/create"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Add new vendor</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>


            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Box2.svg--> */}
                <Box2 />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Permission setting</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all roles</span></a></li>
                        <li className="menu-item " ><a href="/" className="menu-link "><i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Create role</span></a></li>
                    </ul>
                </div>
            </li>

            <li className="menu-item  menu-item-submenu" data-menu-toggle="hover"><a href="/" className="menu-link menu-toggle"><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg--> */}
                <User />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Users</span><i className="menu-arrow"></i></a><div className="menu-submenu "><i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/users/list"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">List all</span>
                            </NavLink>
                        </li>
                        <li className="menu-item " >
                            <NavLink
                                className="menu-link"
                                to={"/users/create"}
                            >
                                <i className="menu-bullet menu-bullet-dot"><span></span></i><span className="menu-text">Add new user</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>			

            <li className="menu-section ">
                <h4 className="menu-text">Additional</h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>

            <li className="menu-item " ><a href="/" className="menu-link " onClick={(e) => logoutWithRedirect(e)}><span className="svg-icon menu-icon">
                {/* <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\706\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Navigation\Sign-out.svg--> */}
                <SignOut />
                {/* <!--end::Svg Icon--> */}
            </span><span className="menu-text">Log out</span></a></li>

        </ul>
    )
}
