import React, { useState } from 'react';

interface Props {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    eid: string;
}

const ProfileButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const btnClass = isOpen ? "fa fa-chevron-down profileButton" : "fa fa-chevron-right profileButton"

    return (
        <td rowSpan={1} className="datatable-cell-center datatable-cell" data-field="RecordID" aria-label="1">
            <button
                data-eid={props.eid}
                className="datatable-toggle-subtable profileButton"
                data-value="1"
                title="Load profiles"
                onClick={(e) => { setIsOpen(!isOpen); props.onClick(e) }}
            >
                <i className={btnClass}></i>
            </button>
        </td>
    )
}

export default ProfileButton