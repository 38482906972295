import React from "react";
import { NavLink } from 'react-router-dom';
import { AngleDoubleLeft } from '../img/icons'

type IAsideP = {
    children: React.ReactChild;
}

export const Aside = (props: IAsideP) => {
    return (
        <div className="aside aside-left  aside-fixed  d-flex flex-column flex-row-auto" id="kt_aside">
            {/*<!--begin::Aside-->*/}
            {/* <!--begin::Brand--> */}
            <div className="brand flex-column-auto " id="kt_brand">
                {/* <!--begin::Logo--> */}
                <NavLink
                    className="brand-logo"
                    to={"/"}
                >
                    <img alt="Logo" src="/assets/media/logos/logo.png" />
                </NavLink>
                {/* <!--end::Logo--> */}
                {/* <!--begin::Toggle--> */}
                <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
                    <span className="svg-icon svg-icon svg-icon-xl">
                        {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-left.svg--> */}
                        <AngleDoubleLeft />
                        {/* <!--end::Svg Icon--> */}
                    </span>			</button>
                {/* <!--end::Toolbar--> */}
            </div>
            {/* <!--end::Brand--> */}
            {/* <!--begin::Aside Menu--> */}
            <div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
                {/* <!--begin::Menu Container--> */}
                <div
                    id="kt_aside_menu"
                    className="aside-menu my-4 "
                    data-menu-vertical="1"
                    data-menu-scroll="1" data-menu-dropdown-timeout="500" 			>
                    {/* <!--begin::Menu Nav--> */}
                    {props.children}
                    {/* <!--end::Menu Nav--> */}
                </div>
                {/* <!--end::Menu Container--> */}
            </div>
            {/* <!--end::Aside Menu--> */}
            {/* <!--end::Aside--> */}
        </div>
    )
}