import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Aside } from './Aside';
import { MenuItems } from './MenuItems';

export const SideBar = () => {
    const { isAuthenticated } = useAuth0();
    if (isAuthenticated)
        return (
            <Aside><MenuItems /></Aside>
        );
    else return <Aside> </Aside>
}
