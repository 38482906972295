import * as React from 'react';
import { IICCxProfile, ICType } from '../../interfaces'

interface Props {
    profiles: IICCxProfile[];
    activate: (e: React.MouseEvent<HTMLButtonElement>) => void;
    forEid: string;
}

interface rowProps {
    profile: IICCxProfile;
    activate: (e: React.MouseEvent<HTMLButtonElement>) => void;
    forEid: string;
}

interface ProfileButtonProps extends rowProps {
    action: number;
}

const waiting4Command = (cmd: ICType, p: IICCxProfile): boolean => {
    if (p.activateCmd) {
        return p.activateCmd.findIndex((c) => c.type === cmd) > -1;
    }
    return false;
}

const ProfileButton = (props: ProfileButtonProps) => {
    const profile = props.profile;
    const btnProps: React.ButtonHTMLAttributes<HTMLButtonElement> = ((p) => {
        const result: React.ButtonHTMLAttributes<HTMLButtonElement> = {
            className: "btn btn-sm btn-outline-secondary"
        };
        if (props.action === 2) {
            result.value = 2
            result.onClick = props.activate;
            result.className += " btn-prof-delete"
        }
        if (props.action === 1) {
            if (p.profileState === '00') {
                result.onClick = () => { };
                result.disabled = true;
            }
            result.onClick = props.activate;
            result.value = 1
            result.className += " btn-prof-disable"
        }
        if (props.action === 0) {
            if (p.profileState === '01') {
                result.onClick = () => { };
                result.disabled = true;
            }
            result.onClick = props.activate;
            result.value = 0;
            result.className += " btn-prof-enable"
        }
        return result;
    })(profile)
    let iconClass: string = '';
    const btnTxt: string = (() => {
        if (props.action === 0) {
            if (waiting4Command(ICType.EnableProfile, profile)) {
                btnProps.className += " btn-success"
                iconClass = "fa fa-cog fa-spin icon-sm icon-anim-fix"
                return 'Clear';
            }
            else {
                iconClass = "fa fa-cog icon-sm"
                return "Activate";
            }
        }
        if (props.action === 1) {
            if (waiting4Command(ICType.DisableProfile, profile)) {
                btnProps.className += " btn-success"
                iconClass = "fas fa-sync-alt fa-spin icon-sm icon-anim-fix"
                return "Clear";
            }
            else {
                iconClass = "fas fa-ban icon-sm"
                return "Disable";
            }
        }
        if (props.action === 2) {
            if (waiting4Command(ICType.DeleteProfile, profile)) {
                btnProps.className += " btn-success"
                iconClass = "fas fa-sync-alt fa-spin icon-sm icon-anim-fix"
                return "Clear";
            }
            else {
                iconClass = "fas fa-trash-alt icon-sm"
                return "Delete";
            }
        }
        return "Activate";
    })()

    return (
        <button
            {...btnProps}
            data-iccid={profile.ICCID}
            data-eid={props.forEid}
        >
            {btnTxt} <i className={iconClass + " btn-icon"}></i>
            </button>
    )
}

export const ProfileRow = (props: rowProps) => {
    const profile = props.profile;
    const profileClass: string = ((p) => {
        if (p.profileState === '01') {
            return "datatable-row activeProfile";
        }
        else if (waiting4Command(ICType.EnableProfile, profile)) {
            return "datatable-row activateProfile";
        }
        else {
            return "datatable-row";
        }
    })(profile)

    //return null;
    return (
        <tr className={profileClass+" sub-table-fix"}>
			<td></td>
            <td className="datatable-cell">{profile.ICCID}</td>
            <td className="datatable-cell">{profile.isdpAid}</td>
            <td className="datatable-cell">{profile.profileClass}</td>
            <td className="datatable-cell">{profile.profileName}</td>
            <td className={profileClass+ " datatable-cell"}><span className="label label-dot" title={profile.profileState}></span></td>
            <td className="datatable-cell">{profile.serviceProviderName}</td>
            <td>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                        <ProfileButton
                            {...props}
                            action={0}
                        />
                        <ProfileButton
                            {...props}
                            action={1}
                        />
                        <ProfileButton
                            {...props}
                            action={2}
                        />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export const ProfileRows = (props: Props) => {

    const profiles: IICCxProfile[] = props.profiles;
    //{props.profiles && props.profiles.map((profile,idx) =>
    if (!profiles.length) return null;
    return (
        <tr className="datatable-row-subtable datatable-row-subtable-even">
            <td className="datatable-subtable" colSpan={7}>
                <table className="table table-separate table-head-custom collapsed compact sub-table-fix">
						<thead className="thead-light">
                                <tr>
									<th className="sub-tr-fix"></th>
                                    <th scope="col">ICCID</th>
                                    <th scope="col">AID</th>
                                    <th scope="col">Profile type</th>
                                    <th scope="col">Nickname</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">SPN</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                    <tbody>
                        {profiles.map((profile, idx) =>
                            <ProfileRow
                                key={`pr${idx}`}
                                profile={profile}
                                activate={props.activate}
                                forEid={props.forEid}
                            />
                        )}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}
